import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { usePrivy } from '@privy-io/react-auth';

import { LogoExpSidebar } from '../../Icons/LogoExpSidebar';
import { LogoRetrSidebar } from '../../Icons/LogoRetrSidebar';
import { useWorkflowCount } from '../../../context/WorkflowCountContext';

export type SidebarProps = {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen, setSidebarOpen }) => {
    const location = useLocation();
    const { pathname } = location;

    // Consume context
    const { authenticated, login } = usePrivy();
    const { workflowCount } = useWorkflowCount();

    const trigger = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
    const sidebar = useRef<HTMLDivElement | null>(null);

    // only for Creation and Overview links
    const protectedLinkClick = (e: React.MouseEvent, isProtected: boolean) => {
        if (isProtected && !authenticated) {
            e.preventDefault();
            login();
        }
    };

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null
            ? false
            : storedSidebarExpanded === 'true',
    );

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: any) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = (event: KeyboardEvent) => {
            if (!sidebarOpen || event.key !== 'escape') return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', String(sidebarExpanded));
        const bodyElem = document.querySelector('body') as HTMLElement;
        if (sidebarExpanded) {
            bodyElem.classList.add('sidebar-expanded');
        } else {
            bodyElem.classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    return (
        <div>
            {/* Sidebar backdrop (mobile only) */}
            <div
                className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-50 lg:hidden lg:z-auto transition-opacity duration-100 ${
                    sidebarOpen
                        ? 'opacity-100'
                        : 'opacity-0 pointer-events-none'
                }`}
                aria-hidden="true"
            ></div>

            {/* Sidebar */}
            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute z-50 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-grey-light border-r p-4 transition-all duration-100 ease-in-out ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-64'
                }`}
            >
                {/* Sidebar header */}
                <div className="flex justify-between mb-10 pr-3 sm:px-2">
                    {/* Close button */}
                    <button
                        ref={
                            trigger as React.MutableRefObject<HTMLButtonElement>
                        }
                        className="lg:hidden text-slate-500 hover:text-slate-600"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                    >
                        <span className="sr-only">Close sidebar</span>
                        <svg
                            className="w-6 h-6 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                        </svg>
                    </button>
                    {/* Logo */}
                    <NavLink end to="/dashboard" className="block mx-auto">
                        <div className="pt-2">
                            {sidebarExpanded ? (
                                <LogoExpSidebar
                                    width={'140px'}
                                    height={'70px'}
                                />
                            ) : (
                                <LogoRetrSidebar
                                    width={'70px'}
                                    height={'35px'}
                                />
                            )}
                        </div>
                    </NavLink>
                </div>

                {/* Links */}
                <div className="space-y-8">
                    {/* Pages group */}
                    <div>
                        {/* <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                            <span
                                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                                aria-hidden="true"
                            >
                                •••
                            </span>
                        </h3> */}
                        <ul className="mt-3">
                            {/* Dashboard */}
                            <li
                                className={`px-3 py-2 rounded-lg mb-1 last:mb-0  ${
                                    pathname.includes('/dashboard') &&
                                    'bg-primary hover:bg-primary'
                                } hover:bg-grey-shadow`}
                            >
                                <NavLink
                                    end
                                    to="/dashboard"
                                    className={`block truncate transition duration-150 ${
                                        pathname.includes('/dashboard')
                                            ? 'text-white'
                                            : 'text-grey-elephant'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        <svg
                                            className="shrink-0 h-6 w-6"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'dashboard',
                                                    )
                                                        ? 'text-blue-300'
                                                        : 'text-slate-400'
                                                }`}
                                                d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'dashboard',
                                                    )
                                                        ? 'text-blue-400'
                                                        : 'text-slate-600'
                                                }`}
                                                d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'dashboard',
                                                    )
                                                        ? 'text-white'
                                                        : 'text-slate-400'
                                                }`}
                                                d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-100">
                                            Dashboard
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            {/* Workflow Creation */}
                            <li
                                className={`px-3 py-2 rounded-lg mb-1 last:mb-0  ${
                                    pathname.includes('/workflow-creation') &&
                                    'bg-primary hover:bg-primary'
                                } hover:bg-grey-shadow`}
                            >
                                <NavLink
                                    end
                                    to="/workflow-creation"
                                    className={`block truncate transition duration-150 ${
                                        pathname.includes('/workflow-creation')
                                            ? 'text-white'
                                            : 'text-grey-elephant'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        <svg
                                            className="shrink-0 h-6 w-6"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'workflow-creation',
                                                    )
                                                        ? 'text-blue-300'
                                                        : 'text-slate-400'
                                                }`}
                                                d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'workflow-creation',
                                                    )
                                                        ? 'text-blue-700'
                                                        : 'text-slate-700'
                                                }`}
                                                d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'workflow-creation',
                                                    )
                                                        ? 'text-blue-500'
                                                        : 'text-slate-500'
                                                }`}
                                                d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-100">
                                            Create
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            {/* Workflow Overview */}
                            <li
                                className={`relative px-3 py-2 rounded-lg mb-1 last:mb-0  ${
                                    pathname.includes('/workflow-overview') &&
                                    'bg-primary hover:bg-primary'
                                } hover:bg-grey-shadow`}
                            >
                                <NavLink
                                    end
                                    to="/workflow-overview"
                                    className={`block truncate transition duration-150 ${
                                        pathname.includes('/workflow-overview')
                                            ? 'text-white'
                                            : 'text-grey-elephant'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        <svg
                                            className="shrink-0 h-6 w-6"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'workflow-overview',
                                                    )
                                                        ? 'text-blue-400'
                                                        : 'text-slate-600'
                                                }`}
                                                d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'workflow-overview',
                                                    )
                                                        ? 'text-blue-400'
                                                        : 'text-slate-600'
                                                }`}
                                                d="M1 1h22v23H1z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'workflow-overview',
                                                    )
                                                        ? 'text-white'
                                                        : 'text-slate-400'
                                                }`}
                                                d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-100">
                                            Workflows
                                        </span>
                                        {/* Workflow Count Indicator */}
                                        {workflowCount > 0 && (
                                            <div className="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 sidebar-expanded:-translate-x-2 sidebar-expanded:translate-y-2.5 bg-red-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                                                {workflowCount}
                                            </div>
                                        )}
                                    </div>
                                </NavLink>
                            </li>
                            <li
                                className={`px-3 py-2 rounded-lg mb-1 mt-1 last:mb-0 ${
                                    pathname.includes('/templates') &&
                                    'bg-primary hover:bg-primary'
                                } hover:bg-grey-shadow`}
                            >
                                <NavLink
                                    end
                                    to="/templates"
                                    className={`block truncate transition duration-150 ${
                                        pathname.includes('/templates')
                                            ? 'text-white'
                                            : 'text-grey-elephant'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        <svg
                                            className="shrink-0 h-6 w-6"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'templates',
                                                    )
                                                        ? 'text-blue-300'
                                                        : 'text-slate-600'
                                                }`}
                                                d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'templates',
                                                    )
                                                        ? 'text-blue-200'
                                                        : 'text-slate-400'
                                                }`}
                                                d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'templates',
                                                    )
                                                        ? 'text-blue-300'
                                                        : 'text-slate-600'
                                                }`}
                                                d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'templates',
                                                    )
                                                        ? 'text-blue-200'
                                                        : 'text-slate-400'
                                                }`}
                                                d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-100">
                                            Templates
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            <li
                                className={`px-3 py-2 rounded-lg mb-1 last:mb-0 ${
                                    pathname.includes('/integrations') &&
                                    'bg-primary hover:bg-primary'
                                } hover:bg-grey-shadow`}
                            >
                                <NavLink
                                    end
                                    to="/integrations"
                                    className={`block truncate transition duration-150 ${
                                        pathname.includes('/integrations')
                                            ? 'text-white'
                                            : 'text-grey-elephant'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        <svg
                                            className="shrink-0 h-6 w-6"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'integrations',
                                                    )
                                                        ? 'text-blue-400'
                                                        : 'text-slate-600'
                                                }`}
                                                d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z"
                                            />
                                            <path
                                                className={`fill-current ${
                                                    pathname.includes(
                                                        'integrations',
                                                    )
                                                        ? 'text-blue-200'
                                                        : 'text-slate-400'
                                                }`}
                                                d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-100">
                                            Integrations
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Expand / collapse button */}
                <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
                    <div
                        className="flex px-3 py-2 rounded-lg hover:bg-grey-shadow cursor-pointer"
                        onClick={() => setSidebarExpanded(!sidebarExpanded)}
                    >
                        {/* <button
                            onClick={() => setSidebarExpanded(!sidebarExpanded)}
                        > */}
                        <span className="sr-only">
                            Expand / collapse sidebar
                        </span>
                        <svg
                            className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                            viewBox="0 0 24 24"
                        >
                            <path
                                className="text-slate-400"
                                d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                            />
                            <path className="text-slate-600" d="M3 23H1V1h2z" />
                        </svg>
                        {/* </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
