import SkeletonLoader from '../UI/SkeletonLoader';

const IntegrationNameLoader = () => (
    <SkeletonLoader
        width={90}
        height={20}
        aria-label="Integration Name Loading"
    >
        <rect y="0" width="90" height="20" rx="3" />
    </SkeletonLoader>
);

const IconLoader = () => (
    <SkeletonLoader width={50} height={50} aria-label="Icon Loading">
        <circle cx={25} cy={25} r={25} />
    </SkeletonLoader>
);

const LoadingIntegrations = () => {
    return (
        <div className="pt-12">
            <div className="grid grid-cols-4 gap-24">
                {Array(7)
                    .fill(null)
                    .map((_, index) => {
                        return (
                            <div className="flex flex-col w-1/2 items-center gap-4 py-4">
                                <IconLoader />
                                <IntegrationNameLoader key={index} />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default LoadingIntegrations;
