import { useState } from 'react';

import { Integrations } from '../utils/types';
import { useIntegrationLibrary } from '../hooks/IntegrationLibrary/useIntegrationLibrary';

import IntegrationElement from '../components/IntegrationLibrary/IntegrationElement';
import IntegrationDialog from '../components/IntegrationLibrary/IntegrationDialog';
import LoadingIntegrations from '../components/IntegrationLibrary/LoadingIntegrations';
import PageTitle from '../components/UI/PageTitle';
import PageContainer from '../components/Core/PageContainer';

const IntegrationLibrary = () => {
    const { integrations, isLoading } = useIntegrationLibrary();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedIntegration, setSelectedIntegration] =
        useState<Integrations | null>(null);

    function closeModal() {
        setIsModalOpen(false);
    }

    function openModal(integration: Integrations) {
        setSelectedIntegration(integration);
        setIsModalOpen(true);
    }

    return (
        <>
            <PageContainer>
                <PageTitle
                    title="Integration Libary"
                    subtitle="View all our integrations"
                />

                {isLoading ? (
                    <LoadingIntegrations />
                ) : (
                    <IntegrationElement
                        integrations={integrations}
                        openModal={openModal}
                    />
                )}
            </PageContainer>
            <IntegrationDialog
                selectedIntegration={selectedIntegration}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
            />
        </>
    );
};

export default IntegrationLibrary;
