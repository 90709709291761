import PageContainer from '../components/Core/PageContainer';
import PageTitle from '../components/UI/PageTitle';
import CategorySection from '../components/WorkflowLibrary/CategorySection';
import TemplateContainer from '../components/WorkflowLibrary/TemplateContainer';

import { useWorkflowLibrary } from '../hooks/WorkflowLibrary/useWorkflowLibrary';

const WorkflowLibrary = () => {
    const {
        templates,
        selectedCategory,
        setSelectedCategory,
        uniqueCategories,
        handleTemplateClick,
    } = useWorkflowLibrary();

    return (
        <PageContainer>
            <PageTitle
                title="Workflow Library"
                subtitle="Find curated workflow templates"
            />

            <div className="flex justify-between pt-12">
                <TemplateContainer
                    templates={templates}
                    selectedCategory={selectedCategory}
                    handleTemplateClick={handleTemplateClick}
                />
                <CategorySection
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    uniqueCategories={uniqueCategories}
                />
            </div>
        </PageContainer>
    );
};

export default WorkflowLibrary;
