import * as React from 'react';

import cx from 'classnames';

export function LoadingDots() {
    return (
        <span
            className={cx(
                'absolute -left-4 top-1/2 flex -translate-x-full -translate-y-1/2 gap-1',
                'pointer-events-none touch-none select-none',
            )}
        >
            <i className="block h-[3px] w-[3px] animate-loading rounded-full bg-current" />
            <i className="block h-[3px] w-[3px] animate-loading-delay-0-5 rounded-full bg-current" />
            <i className="block h-[3px] w-[3px] animate-loading-delay-1 rounded-full bg-current" />
        </span>
    );
}
