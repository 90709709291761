import { useCallback, Fragment } from 'react';
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-hot-toast';
import '../../../styles/WorkflowCreation.css';

import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';

type HeaderProps = {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserMenu = () => {
    const { logout } = usePrivy();
    const navigate = useNavigate();

    return (
        <div className="w-full max-w-sm">
            <Popover className="">
                {({ open }) => (
                    <>
                        <Popover.Button
                            className={`
                    ${open ? '' : 'text-opacity-90'}
                    group inline-flex items-center rounded-md bg-[#0560FD] px-3 py-2 text-base font-medium text-white hover:brightness-95 `}
                        >
                            <IconThree aria-hidden="true" />
                            <ChevronDownIcon
                                className={`${open ? '' : 'rotate-180'}
                      ml-2 h-5 w-5 text-white transition duration-150 ease-in-out text-opacity-80 group-hover:brightness-95`}
                                aria-hidden="true"
                            />
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute right-0 z-50 mt-3 px-4 sm:px-0 lg:max-w-3xl w-4/5 sm:w-7/10 md:w-1/3">
                                {({ close }) => (
                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div
                                            className="relative grid gap-8 bg-white p-7"
                                            onClick={() => {
                                                navigate('/profile');
                                                close();
                                            }}
                                        >
                                            <div className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 cursor-pointer">
                                                <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                                    <IconOne aria-hidden="true" />
                                                </div>
                                                <div className="flex flex-col w-fit ml-4">
                                                    <p className="text-sm font-medium text-gray-900">
                                                        {'Profile'}
                                                    </p>
                                                    <p className="text-xs text-gray-500">
                                                        {
                                                            'Change your settings and link your social accounts'
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                onClick={logout}
                                                className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 cursor-pointer"
                                            >
                                                <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                                    <IconTwo aria-hidden="true" />
                                                </div>
                                                <div className="ml-4">
                                                    <p className="text-sm font-medium text-gray-900">
                                                        {'Logout'}
                                                    </p>
                                                    <p className="text-xs text-gray-500">
                                                        {'See you soon!'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 p-4">
                                            <div className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out">
                                                <span className="flex items-center">
                                                    <span className="text-sm font-medium text-gray-900">
                                                        Developer Documentation
                                                    </span>
                                                </span>
                                                <span className="block text-xs text-gray-500">
                                                    {/* Start integrating products
                                                    and tools */}
                                                    Coming Soon!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    );
};

export const Header: React.FC<HeaderProps> = ({
    sidebarOpen,
    setSidebarOpen,
}) => {
    const { wallets } = useWallets();

    const { login, ready, authenticated } = usePrivy();

    const embeddedWallet = wallets.find(
        (wallet) => wallet.walletClientType === 'privy',
    );
    const embeddedWalletAddress = embeddedWallet
        ? embeddedWallet.address
        : 'Not connected';
    const truncatedAddress =
        embeddedWalletAddress.slice(0, 4) +
        '...' +
        embeddedWalletAddress.slice(-4);

    const handleCopyAddress = useCallback(() => {
        if (embeddedWalletAddress) {
            navigator.clipboard
                .writeText(embeddedWalletAddress)
                .then(() => {
                    toast.success('Copied Privy Address!');
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                    toast.error('Failed to copy.');
                });
        }
    }, [embeddedWalletAddress]);

    return (
        <header className="top-0 z-30">
            <div className="px-4 sm:px-6 pt-4">
                <div className="flex items-center justify-between h-16 -mb-px">
                    {/* Header: Left side */}
                    <div className="flex">
                        {/* Hamburger button */}
                        <button
                            className="text-slate-500 hover:text-slate-600 lg:hidden"
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSidebarOpen(!sidebarOpen);
                            }}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg
                                className="w-6 h-6 fill-current"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect x="4" y="5" width="16" height="2" />
                                <rect x="4" y="11" width="16" height="2" />
                                <rect x="4" y="17" width="16" height="2" />
                            </svg>
                        </button>
                    </div>
                    {/* Header: Right side */}
                    <div className="flex items-center space-x-3">
                        <div
                            className="flex items-center"
                            onClick={handleCopyAddress}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className={
                                    embeddedWallet ? 'green-dot' : 'red-dot'
                                }
                            />
                            <p>
                                {embeddedWallet
                                    ? `${truncatedAddress}`
                                    : 'Not Connected'}
                            </p>
                        </div>
                        <div className="pl-10">
                            {ready && !authenticated ? (
                                <Button
                                    size="md"
                                    className="bg-blue-400 text-white p-2 rounded-lg border-none hover:bg-blue-300"
                                    onClick={login}
                                >
                                    Login
                                </Button>
                            ) : (
                                <div className="flex">
                                    <UserMenu />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

function IconOne() {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 5C0 2.23858 2.23858 0 5 0H43C45.7614 0 48 2.23858 48 5V43C48 45.7614 45.7614 48 43 48H5C2.23858 48 0 45.7614 0 43V5Z"
                fill="#E6EFFF"
            />
            <circle cx="24" cy="23" r="3" stroke="#69A0FE" strokeWidth="2" />
            <path
                d="M16 32C16 32 20.7268 29 24 29C27.2732 29 32 32 32 32"
                stroke="#69A0FE"
                strokeWidth="2"
            />
            <circle cx="24" cy="24" r="11" stroke="#044DCA" strokeWidth="2" />
        </svg>
    );
}

function IconTwo() {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 5C0 2.23858 2.23858 0 5 0H43C45.7614 0 48 2.23858 48 5V43C48 45.7614 45.7614 48 43 48H5C2.23858 48 0 45.7614 0 43V5Z"
                fill="#E6EFFF"
            />
            <path
                d="M21.9738 27.1016C22.3915 27.528 22.8902 27.8667 23.4405 28.098C23.9909 28.3293 24.5818 28.4484 25.1788 28.4484C25.7758 28.4484 26.3667 28.3293 26.9171 28.098C27.4674 27.8667 27.9661 27.528 28.3839 27.1016L33.5119 21.9735C34.362 21.1235 34.8395 19.9706 34.8395 18.7685C34.8395 17.5663 34.362 16.4134 33.5119 15.5634C32.6619 14.7134 31.509 14.2358 30.3069 14.2358C29.1048 14.2358 27.9519 14.7134 27.1018 15.5634L26.4608 16.2044"
                fill="#E6EFFF"
            />
            <path
                d="M21.9738 27.1016C22.3915 27.528 22.8902 27.8667 23.4405 28.098C23.9909 28.3293 24.5818 28.4484 25.1788 28.4484C25.7758 28.4484 26.3667 28.3293 26.9171 28.098C27.4674 27.8667 27.9661 27.528 28.3839 27.1016L33.5119 21.9735C34.362 21.1235 34.8395 19.9706 34.8395 18.7685C34.8395 17.5663 34.362 16.4134 33.5119 15.5634C32.6619 14.7134 31.509 14.2358 30.3069 14.2358C29.1048 14.2358 27.9519 14.7134 27.1018 15.5634L26.4608 16.2044"
                stroke="#69A0FE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.1015 21.9747C26.6837 21.5483 26.185 21.2096 25.6347 20.9783C25.0843 20.747 24.4934 20.6279 23.8964 20.6279C23.2995 20.6279 22.7085 20.747 22.1582 20.9783C21.6078 21.2096 21.1092 21.5483 20.6914 21.9747L15.5633 27.1028C14.7133 27.9528 14.2357 29.1057 14.2357 30.3078C14.2357 31.51 14.7133 32.6629 15.5633 33.5129C16.4133 34.3629 17.5662 34.8405 18.7683 34.8405C19.9705 34.8405 21.1234 34.3629 21.9734 33.5129L22.6144 32.8719"
                stroke="#69A0FE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.6654 36.0755V33.5115"
                stroke="#044DCA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.5112 29.6653H36.0753"
                stroke="#044DCA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 19.4099H15.564"
                stroke="#044DCA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.4098 13V15.564"
                stroke="#044DCA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function IconThree() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="white"
            className="w-5 h-5 mr-2"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
            />
        </svg>
    );
}
