import { Template } from '../../utils/types';
import TemplateElement from './TemplateElement';

interface TemplateContainerProps {
    templates: Template[];
    selectedCategory: string;
    handleTemplateClick: (workflow: Template) => void;
}

const TemplateContainer: React.FC<TemplateContainerProps> = ({
    templates,
    selectedCategory,
    handleTemplateClick,
}) => {
    return (
        <div className="grid grid-cols-3 gap-4">
            {templates
                .filter((template: Template) => {
                    if (selectedCategory === 'All') return true;
                    return template.workflow_data.some((item) => {
                        if (
                            'category' in item &&
                            item.category === selectedCategory
                        ) {
                            return true;
                        } else if (
                            'components' in item &&
                            item.components.some(
                                (component) =>
                                    component.category === selectedCategory,
                            )
                        ) {
                            return true;
                        }
                        return false;
                    });
                })
                .map((template: Template, index) => (
                    <TemplateElement
                        key={index}
                        template={template}
                        onTemplateClick={handleTemplateClick}
                    />
                ))}
        </div>
    );
};

export default TemplateContainer;
