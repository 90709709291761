import * as React from 'react';

export const LogoExpSidebar = React.forwardRef<
    SVGSVGElement,
    React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
    <svg
        viewBox="0 0 357 106"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <line
            x1="2.66956"
            y1="105.095"
            x2="42.6696"
            y2="2.09498"
            stroke="#0560FD"
            strokeWidth="5"
        />
        <line
            x1="47.3304"
            y1="2.09498"
            x2="87.3304"
            y2="105.095"
            stroke="#0560FD"
            strokeWidth="5"
        />
        <line
            x1="101.967"
            y1="2.45715"
            x2="181.967"
            y2="104.457"
            stroke="#0560FD"
            strokeWidth="5"
        />
        <line
            x1="102.033"
            y1="104.457"
            x2="182.033"
            y2="2.45714"
            stroke="#0560FD"
            strokeWidth="5"
        />
        <line
            x1="297"
            y1="103.5"
            x2="357"
            y2="103.5"
            stroke="#0560FD"
            strokeWidth="5"
        />
        <line
            x1="299.5"
            y1="1"
            x2="299.5"
            y2="106"
            stroke="#0560FD"
            strokeWidth="5"
        />
        <rect x="40.34" y="1" width="9.32" height="5" fill="#0560FD" />
        <line
            x1="196.67"
            y1="105.095"
            x2="236.67"
            y2="2.09498"
            stroke="#0560FD"
            strokeWidth="5"
        />
        <line
            x1="241.33"
            y1="2.09498"
            x2="281.33"
            y2="105.095"
            stroke="#0560FD"
            strokeWidth="5"
        />
        <rect x="234.34" y="1" width="9.32" height="5" fill="#0560FD" />
    </svg>
));

LogoExpSidebar.displayName = 'LogoExpSidebar';
