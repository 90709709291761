import { ChevronRightIcon } from '@heroicons/react/20/solid';
import SkeletonLoader from '../UI/SkeletonLoader';

const WorkflowNameLoader = ({
    width,
    height,
}: {
    width: number;
    height: number;
}) => (
    <SkeletonLoader
        width={width}
        height={height}
        aria-label="Integration Name Loading"
    >
        <rect y="0" width={width} height={height} rx="3" />
    </SkeletonLoader>
);

const IconLoader = () => (
    <SkeletonLoader width={44} height={44} aria-label="Icon Loading">
        <circle cx={22} cy={22} r={22} />
    </SkeletonLoader>
);

const LoadingWorkflowOverview = () => {
    return (
        <div className="bg-white rounded-xl drop-shadow-md pb-1">
            <div className="grid xl:grid-cols-[3fr_1fr] lg:grid-cols-[2fr_1fr] grid-cols-[1fr_1fr] p-4 items-center">
                <div className="flex rounded-lg mr-4 py-3">
                    <ChevronRightIcon className="h-6 w-6 mr-1 text-blue-600" />
                    <div className="relative flex items-center">
                        <div className="absolute p-2" style={{ left: '4px' }}>
                            <IconLoader />
                        </div>
                        <div style={{ paddingLeft: '150px' }}>
                            <WorkflowNameLoader width={120} height={20} />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <WorkflowNameLoader width={80} height={20} />
                </div>
            </div>
        </div>
    );
};

export default LoadingWorkflowOverview;
