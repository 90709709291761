import { useEffect, useState } from 'react';
import axios from 'axios';
import { usePrivy } from '@privy-io/react-auth';

import { Integrations } from '../../utils/types';

export type UseIntegrationLibrary = {
    integrations: Integrations[];
    setIntegrations: React.Dispatch<React.SetStateAction<Integrations[]>>;
    isLoading: boolean;
};

export function useIntegrationLibrary(): UseIntegrationLibrary {
    const { ready, getAccessToken } = usePrivy();
    const [integrations, setIntegrations] = useState<Integrations[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            const authToken = await getAccessToken();
            try {
                const response = await axios.get(
                    `${baseURL}/app/integrations/`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    },
                );
                setIntegrations(response.data.integrations);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching integrations:', error);
                setIsLoading(false);
            }
        };

        ready && fetchData();
    }, [ready]);

    return {
        integrations,
        setIntegrations,
        isLoading,
    };
}
