import React, { createContext, useState, useCallback, ReactNode } from 'react';
import { AuthContextType, AuthState } from '../utils/types/AuthTypes';
import { usePrivy } from '@privy-io/react-auth';
import axios from 'axios';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const { getAccessToken } = usePrivy();
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const [authState, setAuthState] = useState<AuthState>({
        gmail: { isLoading: false, hasAccess: false },
        discord: { isLoading: false, hasAccess: false },
    });

    const handleGmailLogin = useCallback(() => {
        const GMAIL_CLIENT_ID = process.env.REACT_APP_GMAIL_CLIENT_ID;
        const GMAIL_REDIRECT_URL = process.env.REACT_APP_GMAIL_REDIRECT_URL;
        const redirectUri = GMAIL_REDIRECT_URL && encodeURI(GMAIL_REDIRECT_URL);
        const scope =
            'https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.compose%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify%20email';

        const authUrl = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&approval_prompt=force&client_id=${GMAIL_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&service=lso&o2v=1&theme=glif&flowName=GeneralOAuthFlow`;

        const width = 600,
            height = 700;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        setAuthState((prev) => {
            return { ...prev, gmail: { ...prev.gmail, isLoading: true } };
        });

        const popup = window.open(
            authUrl,
            'GmailAuthPopup',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
        );

        if (popup) {
            const interval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(interval);
                }
            }, 1000);
        } else {
            alert(
                'Pop-up was blocked. Please allow pop-ups for this site and try again.',
            );
        }
    }, []);

    const checkGmailAccess = useCallback(async (): Promise<boolean> => {
        setAuthState((prev) => {
            return { ...prev, gmail: { ...prev.gmail, isLoading: true } };
        });
        const authToken = await getAccessToken();
        try {
            const response = await axios.get(
                `${baseURL}/api/gmail/check-gmail-access`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            );
            if (response.data.hasGmailAccess || response.data.success) {
                setAuthState((prev) => {
                    return {
                        ...prev,
                        gmail: { ...prev.gmail, hasAccess: true },
                    };
                });
                return true;
            } else {
                setAuthState((prev) => {
                    return {
                        ...prev,
                        gmail: { ...prev.gmail, hasAccess: false },
                    };
                });
                return false;
            }
        } catch (error) {
            console.error('Error checking Gmail Access Tokens: ', error);
            setAuthState((prev) => {
                return { ...prev, gmail: { ...prev.gmail, hasAccess: false } };
            });
            return false;
        } finally {
            setAuthState((prev) => {
                return { ...prev, gmail: { ...prev.gmail, isLoading: false } };
            });
        }
    }, []);

    const handleDiscordLogin = useCallback(() => {
        const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
        const DISCORD_REDIRECT_URL = process.env.REACT_APP_DISCORD_REDIRECT_URL;
        const discordRedirectUri =
            DISCORD_REDIRECT_URL && encodeURI(DISCORD_REDIRECT_URL);

        // TODO: Include `state` for security (https://discord.com/developers/docs/topics/oauth2#state-and-security)
        const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&response_type=code&redirect_uri=${discordRedirectUri}&scope=webhook.incoming`;

        const width = 600,
            height = 700;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        setAuthState((prev) => {
            return { ...prev, discord: { ...prev.discord, isLoading: true } };
        });

        const popup = window.open(
            discordAuthUrl,
            'DiscordAuthPopup',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
        );

        if (popup) {
            const interval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(interval);
                }
            }, 1000);
        } else {
            alert(
                'Pop-up was blocked. Please allow pop-ups for this site and try again.',
            );
        }
    }, []);

    const checkDiscordAccess = useCallback(async (): Promise<boolean> => {
        setAuthState((prev) => {
            return { ...prev, discord: { ...prev.discord, isLoading: true } };
        });
        const authToken = await getAccessToken();
        try {
            const response = await axios.get(
                `${baseURL}/api/discord/check-discord-access`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            );
            if (response.data.hasDiscordAccess || response.data.success) {
                setAuthState((prev) => {
                    return {
                        ...prev,
                        discord: { ...prev.discord, hasAccess: true },
                    };
                });
                return true;
            } else {
                setAuthState((prev) => {
                    return {
                        ...prev,
                        discord: { ...prev.discord, hasAccess: false },
                    };
                });
                return false;
            }
        } catch (error) {
            console.error('Error executing Discord webhook: ', error);
            setAuthState((prev) => {
                return {
                    ...prev,
                    discord: { ...prev.discord, hasAccess: false },
                };
            });
            return false;
        } finally {
            setAuthState((prev) => {
                return {
                    ...prev,
                    discord: { ...prev.discord, isLoading: false },
                };
            });
        }
    }, []);

    const contextValue: AuthContextType = {
        authState,
        setAuthState,
        handleGmailLogin,
        checkGmailAccess,
        handleDiscordLogin,
        checkDiscordAccess,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
