import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { snakeToReadable } from '../../../utils/helpers';
import { CheckIcon } from '../../Icons/CheckIcon';

import { Option } from '../../../utils/types';

interface CustomListBoxProps {
    selected: Option;
    setSelected: (value: Option) => void;
    options: Option[];
    className?: string;
}

const CustomListBox: React.FC<CustomListBoxProps> = ({
    selected,
    setSelected,
    options,
    className,
}) => {
    const handleChange = (id: string) => {
        const newSelected = options.find((option) => option.id === id);
        if (newSelected) setSelected(newSelected);
    };

    return (
        <div className={className}>
            <Listbox value={selected.id} onChange={handleChange}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md hover:brightness-95 sm:text-sm">
                        <span className="block truncate">
                            {snakeToReadable(selected.label || '')}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-40 w-full overflow-auto z-10 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {options.map((option: Option) => (
                                <Listbox.Option
                                    key={option.id}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active
                                                ? 'bg-blue-100 text-blue-900'
                                                : 'text-gray-900'
                                        }`
                                    }
                                    value={option.id}
                                >
                                    {() => {
                                        const isSelected =
                                            selected.id === option.id;
                                        return (
                                            <>
                                                <span
                                                    className={`block truncate ${
                                                        isSelected
                                                            ? 'font-medium'
                                                            : 'font-normal'
                                                    }`}
                                                >
                                                    {snakeToReadable(
                                                        option.label || '',
                                                    )}
                                                </span>
                                                {isSelected ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                                        <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        );
                                    }}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};

export default CustomListBox;
