import { snakeToReadable } from '../../utils/helpers';
import { Integrations } from '../../utils/types';

const IntegrationElement = ({
    integrations,
    openModal,
}: {
    integrations: Integrations[];
    openModal: (integration: Integrations) => void;
}) => {
    return (
        <div className="pt-12">
            <div className="grid grid-cols-4 gap-24">
                {integrations.map((integration, idx) => {
                    return (
                        <div
                            key={idx}
                            className="flex flex-col w-1/2 items-center gap-4 py-4 rounded-xl hover:bg-grey-shadow/40 cursor-pointer"
                            onClick={() => openModal(integration)}
                        >
                            <img
                                alt="Integration icon"
                                className="w-12 h-12"
                                src={
                                    process.env.PUBLIC_URL +
                                    `${integration.app}.png`
                                }
                            />
                            <p>{snakeToReadable(integration.app)}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default IntegrationElement;
