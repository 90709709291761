import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { snakeToReadable } from '../../utils/helpers';
import { Integrations, Task } from '../../utils/types';

const IntegrationDialog = ({
    selectedIntegration,
    isModalOpen,
    closeModal,
}: {
    selectedIntegration: Integrations | null;
    isModalOpen: boolean;
    closeModal: () => void;
}) => {
    function renderTasks(task: Task, idx: number) {
        return (
            <div
                key={idx}
                className={
                    'flex flex-col bg-white rounded-lg drop-shadow-md hover:bg-slate-100 cursor-pointer'
                }
            >
                <div className="h-full flex flex-col justify-between">
                    <div className="p-2">
                        <p className="text-sm">{snakeToReadable(task.name)}</p>
                        <div className="py-2">
                            <p className="text-xs text-grey-metal">
                                {task.description}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="border-b border-grey-shadow dark:border-grey-night" />
                        <div className="p-2">
                            <div className="px-3 py-1 max-w-fit bg-white border border-slate-200 drop-shadow-sm rounded-2xl">
                                <p className="text-xs">
                                    {snakeToReadable(task.type)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Transition appear show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="min-w-fit sm:min-w-[450px] max-w-4xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div
                                    className="absolute top-2 right-2 bg-gray-200 hover:bg-gray-300 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer transition-colors duration-300"
                                    onClick={closeModal}
                                >
                                    x
                                </div>
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {snakeToReadable(
                                        selectedIntegration?.app || '',
                                    )}
                                </Dialog.Title>
                                <div className="pb-2">
                                    {selectedIntegration?.tasks.some(
                                        (task) => task.type === 'listener',
                                    ) && (
                                        <div className="mt-10">
                                            <div className="p-2 mb-4 bg-grey-cloudy text-grey-metal rounded-md">
                                                <p className="pl-2 text-sm">
                                                    LISTENER
                                                </p>
                                            </div>
                                            <div className="grid grid-cols-3 gap-2">
                                                {selectedIntegration?.tasks
                                                    ?.filter(
                                                        (task) =>
                                                            task.type ===
                                                            'listener',
                                                    )
                                                    .map((task, idx) => {
                                                        return renderTasks(
                                                            task,
                                                            idx,
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    )}
                                    {selectedIntegration?.tasks.some(
                                        (task) => task.type === 'action',
                                    ) && (
                                        <div className="mt-10">
                                            <div className="p-2 mb-4 bg-grey-cloudy text-grey-metal rounded-md">
                                                <p className="pl-2 text-sm">
                                                    ACTIONS
                                                </p>
                                            </div>
                                            <div className="grid grid-cols-3 gap-2">
                                                {selectedIntegration?.tasks
                                                    ?.filter(
                                                        (task) =>
                                                            task.type ===
                                                            'action',
                                                    )
                                                    .map((task, idx) => {
                                                        return renderTasks(
                                                            task,
                                                            idx,
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default IntegrationDialog;
