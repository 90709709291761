import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    EnhancedAction,
    EnhancedListener,
    EnhancedWorkflow,
    Switch,
    TransitionTemplate,
    TransitionWorkflowData,
} from '../utils/types';
import {
    isEnhancedAction,
    isEnhancedGate,
    isOverviewSwitch,
} from '../utils/helpers';
import WorkflowElement from '../components/WorkflowOverview/WorkflowElement';
import PageTitle from '../components/UI/PageTitle';
import { useWorkflowManagement } from '../hooks/useWorkflowManagement';
import PageContainer from '../components/Core/PageContainer';
import LoadingWorkflowOverview from '../components/WorkflowOverview/LoadingWorkflowOverview';

const WorkflowOverview = () => {
    const navigate = useNavigate();

    const {
        isLoading,
        integrations,
        workflows,
        handleResume,
        handlePause,
        handleDelete,
    } = useWorkflowManagement();

    function handleEditWorkflow(workflow: EnhancedWorkflow) {
        const templateWorkflowData: TransitionWorkflowData[] =
            workflow?.workflow_data?.map(
                (data: EnhancedListener | EnhancedAction | Switch) => {
                    if (isEnhancedGate(data))
                        return {
                            integrationName: '',
                            taskName: '',
                            taskType: '',
                            inputs: [],
                        };
                    return isEnhancedAction(data)
                        ? {
                              cardId: data.components?.[0].component_id,
                              integrationName: data.components?.[0].app,
                              taskName: data.components?.[0].name,
                              taskType: data.type,
                              inputs: data.components?.[0]?.inputs,
                          }
                        : {
                              cardId: data.component_id,
                              integrationName: data.app,
                              taskName: data.name,
                              taskType: data.type,
                              inputs: data.inputs,
                          };
                },
            );
        const template: TransitionTemplate = {
            workflowName: workflow.workflow_name,
            workflowData: templateWorkflowData,
        };
        navigate('/workflow-creation', { state: { template } });
    }

    const filteredIntegrationsForWorkflows = useMemo(() => {
        return workflows.map((workflow: EnhancedWorkflow) =>
            integrations.filter((integration) =>
                integration.tasks.some((task) =>
                    workflow.workflow_data.some((wd) => {
                        if (isOverviewSwitch(wd)) return false;
                        return isEnhancedAction(wd)
                            ? wd.components?.[0].name === task.name
                            : wd.name === task.name;
                    }),
                ),
            ),
        );
    }, [workflows, integrations]);

    return (
        <PageContainer>
            <PageTitle
                title="Workflow Overview"
                subtitle="Monitor active and historic workflows"
            />

            {isLoading ? (
                <LoadingWorkflowOverview />
            ) : (
                <>
                    {workflows.length === 0 ? (
                        <p>No workflows</p>
                    ) : (
                        <div className="space-y-8">
                            {workflows.map(
                                (workflow: EnhancedWorkflow, index: number) => {
                                    return (
                                        <WorkflowElement
                                            key={index}
                                            workflow={workflow}
                                            filteredIntegrations={
                                                filteredIntegrationsForWorkflows[
                                                    index
                                                ]
                                            }
                                            handleResume={handleResume}
                                            handlePause={handlePause}
                                            handleDelete={handleDelete}
                                            handleEditWorkflow={() =>
                                                handleEditWorkflow(workflow)
                                            }
                                        />
                                    );
                                },
                            )}
                        </div>
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default WorkflowOverview;
