import { useEffect, useState } from 'react';
import axios from 'axios';
import { usePrivy } from '@privy-io/react-auth';

export function useGenerateWorkflowId() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { getAccessToken } = usePrivy();

    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchData = async () => {
            const authToken = await getAccessToken();
            try {
                const response = await axios.get(
                    `${baseURL}/app/workflow-overview/`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    },
                );
                setData(response.data.all_workflows);
                setLoading(false);
            } catch (error) {
                console.error(
                    'Error fetching workflow overview for getting workflow id in create:',
                    error,
                );
                setLoading(false);
            }
        };

        fetchData();
    }, [getAccessToken]);

    const rnd = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    const result = data.length + rnd;

    return { result, loading };
}
