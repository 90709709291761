// ChatInput.tsx

import React, { useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

type PredefinedMessages = {
  Transactions: string;
  "Address Monitoring": string;
  Reports: string;
  "User Engagement": string;
};

interface ChatInputProps {
  placeholder: string;
  onSend: (message: string) => void;
  isLoading: boolean; // Add this line
}

const ChatInput: React.FC<ChatInputProps> = ({
  placeholder,
  onSend,
  isLoading,
}) => {
  const [message, setMessage] = useState("");

  const predefinedMessages: PredefinedMessages = {
    Transactions: "Transfer 1 MATIC to 0x... then email me a receipt",
    "Address Monitoring":
      "Monitor this address for users, logging new user info to a spreadsheet",
    Reports:
      "Every week, update a spreadsheet report with TVL and growth for this contract",
    "User Engagement":
      "If an address transfers over 100 USDC to our contract, airdrop them an NFT",
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSend = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      onSend(trimmedMessage);
      setMessage("");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const handleLabelClick = (label: keyof PredefinedMessages) => {
    setMessage(predefinedMessages[label]);
  };

  return (
    <>
      <style>
        {`
                    @keyframes glowing {
                        -100%, 100% { box-shadow: 0 0 16px #38bdf8, 0 0 12px #38bdf8; }
                        50% { box-shadow: 0 0 20px #38bdf8, 0 0 30px #38bdf8; }
                    }
                `}
      </style>

      <div className="flex flex-col items-center mt-4">
        <div className="relative group w-7/12 max-w-2xl">
          <div
            className="absolute -inset-1 rounded-full bg-gradient-to-r from-blue-300 via-sky-400 via-blue-400 via-blue-500 via-blue-400 via-sky-400 to-blue-300 blur opacity-50 group-hover:opacity-100 transition duration-500 group-hover:duration-500"
            style={{ animation: "glowing 2.5s ease-in-out infinite" }}
          ></div>
          <div className="flex items-center justify-between rounded-full px-4 py-3 shadow-lg bg-white relative">
            <input
              type="text"
              placeholder={placeholder}
              className="w-full focus:outline-none bg-transparent text-gray-400 placeholder-gray-400"
              value={message}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            <button className="focus:outline-none" onClick={handleSend}>
              {isLoading ? (
                <PuffLoader size={24} color={"#38bdf8"} />
              ) : (
                <svg
                  className="w-6 h-6 text-sky-400"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className="w-7/12 max-w-2xl flex justify-center space-x-4 mt-8">
          {[
            "Transactions",
            "Address Monitoring",
            "Reports",
            "User Engagement",
          ].map((label) => (
            <div
              key={label}
              onClick={() =>
                handleLabelClick(label as keyof PredefinedMessages)
              }
              className="group px-3 py-1 rounded-2xl bg-white border border-gray-300 hover:border-sky-400 transition duration-500 shadow-sm relative cursor-pointer"
            >
              <p className="text-xs text-gray-400 group-hover:text-sky-400 transition duration-500">
                {label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChatInput;
