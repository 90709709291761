import type { ReactNode } from 'react';
import { Suspense } from 'react';
import { useQueryErrorResetBoundary } from 'react-query';

import { ErrorBoundary } from '../ErrorBoundary';
import { Header } from '../Header';

import { useBreakpoint } from '../../../hooks/Core/useBreakpoint';

import SkeletonLoader from '../../UI/SkeletonLoader';

type MainLayoutProps = {
    sidebarOpen?: boolean;
    setSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    children?: ReactNode;
};

function MainLayoutLoader() {
    const breakpoint = useBreakpoint();
    const isSmall = breakpoint === 'sm';
    const width = isSmall ? 300 : 410;

    return (
        <SkeletonLoader
            width={width}
            height={300}
            viewBox={`0 0 ${width} 300`}
            backgroundColor="#17191C"
            foregroundColor="#2D3138"
        >
            <rect y="0" width="82" height="37" rx="10" />
            <rect y="51" width={width} height="85" rx="10" />
            <rect y="150" width={width} height="85" rx="10" />
            <rect y="249" width={width} height="51" rx="10" />
        </SkeletonLoader>
    );
}

export const MainLayout = ({
    sidebarOpen = true,
    setSidebarOpen,
    children,
}: MainLayoutProps) => {
    const { reset: resetReactQuery } = useQueryErrorResetBoundary();

    return (
        <div className="bg-gradient-to-br from-grey-light via-blue-50 to-grey-light">
            <main className="min-h-screen">
                <Header
                    sidebarOpen={sidebarOpen!}
                    setSidebarOpen={setSidebarOpen!}
                />
                <div className="">
                    <ErrorBoundary onReset={resetReactQuery}>
                        <Suspense fallback={<MainLayoutLoader />}>
                            {children}
                        </Suspense>
                    </ErrorBoundary>
                </div>
            </main>
        </div>
    );
};
