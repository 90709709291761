import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';
import { LogoExpSidebar } from '../Icons/LogoExpSidebar';

import {
    AIWorkflow,
    EnhancedWorkflow,
    Integrations,
    TransitionWorkflowData,
    TransitionTemplate,
    Action,
    Listener,
    Switch,
} from '../../utils/types';

import { WorkflowOverviewWidget } from './includes';
import { useFetchEndpoint } from '../../hooks/Data';
import { isAction, isGate } from '../../utils/helpers';

import ChatInput from '../Chat/ChatInput';

import axios from 'axios';

interface DashboardProps {}

const AIWorkflowWidget = () => {
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const { ready, authenticated, login, getAccessToken } = usePrivy();
    const [isLoading, setIsLoading] = useState(false); // Add a loading state

    const sendMessage = async (message: string) => {
        setIsLoading(true); // Set loading to true when the request starts
        const authToken = await getAccessToken();

        try {
            const response = await axios.post(
                `${baseURL}/app/workflow-chatbot/`,
                {
                    query: message,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            );

            const aiWorkflowData = response.data.ai_workflow_data;

            // loop through every entry (Arrays) in aiWorkflowData, adding the first element of each array as the integration name and the second element as the task name

            const templateWorkflowData: TransitionWorkflowData[] =
                aiWorkflowData?.map((data: Listener | Action | Switch) => {
                    if (isGate(data))
                        return {
                            integrationName: '',
                            taskName: '',
                            taskType: '',
                            inputs: [],
                        };
                    return isAction(data)
                        ? {
                              integrationName: data.components?.[0].app,
                              taskName: data.components?.[0].name,
                              taskType: data.type,
                              inputs: data.components?.[0]?.inputs,
                          }
                        : {
                              integrationName: data.app,
                              taskName: data.name,
                              taskType: data.type,
                              inputs: data.inputs,
                          };
                });
            const template: TransitionTemplate = {
                workflowData: templateWorkflowData,
                aiMessage: message,
            };
            navigate('/workflow-creation', { state: { template } });
        } catch (error: any) {
            toast.error(
                <>
                    Sorry, the AI could not construct a valid workflow - please
                    try again with clear ordered steps and task names!
                </>,
            );
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    const placeholderOptions = [
        'Email me when ETH is above $2000...',
        'Add a row to a spreadsheet when a new user interacts with my contract...',
        'Every week, transfer all revenue in my contract to a different wallet...',
        'Slack compliance when an employee wallet spends over $100...',
        'Show me all Bored Ape Holders with a balance over 2 ETH...',
        'Send me an email when a new NFT is minted...',
    ];

    // randomly choose a placeholder to use
    const randomPlaceholder =
        placeholderOptions[
            Math.floor(Math.random() * placeholderOptions.length)
        ];

    return (
        <div className="container mx-auto p-4">
            <ChatInput
                placeholder={randomPlaceholder}
                onSend={sendMessage}
                isLoading={isLoading}
            />
        </div>
    );
};

const Dashboard: React.FC<DashboardProps> = () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const { ready, authenticated } = usePrivy();

    // fetch any existing workflows a user has
    const fetchWorkflows = useFetchEndpoint<EnhancedWorkflow[]>(
        `${baseURL}/app/workflow-overview/`,
        'all_workflows',
    );

    const {
        data: workflows,
        loading: loadingWorkflows,
        error: errorWorkflows,
    } = fetchWorkflows;

    useEffect(() => {
        if (ready && authenticated) {
            fetchWorkflows.refetch();
        }
    }, [ready, authenticated]);

    return (
        <div className="w-full p-6">
            <div className="flex flex-col items-center justify-center mt-20 mb-4">
                <LogoExpSidebar width={'320px'} height={'160px'} />
                {/* <p className="text-2xl mt-4">How can I help you today?</p> */}
            </div>

            <AIWorkflowWidget />
            {!authenticated ||
            loadingWorkflows ||
            workflows?.length === 0 ? null : (
                <WorkflowOverviewWidget
                    workflows={workflows || []}
                    loadingWorkflows={loadingWorkflows}
                    errorWorkflows={errorWorkflows}
                />
            )}
        </div>
    );
};

export default Dashboard;
