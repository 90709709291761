import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { ArrowRightIcon } from '../Icons/ArrowRightIcon';
import { Button } from '../Core/Button';
import { isAction, isPath, snakeToReadable } from '../../utils/helpers';
import { Template } from '../../utils/types';

interface TemplateElementProps {
    template: Template;
    onTemplateClick: (template: Template) => void;
}

const TemplateElement: React.FC<TemplateElementProps> = ({
    template,
    onTemplateClick,
}) => {
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    function handleClick(template: Template) {
        closeModal();

        onTemplateClick(template);
    }

    const allCategories = template.workflow_data.flatMap((layer) => {
        if (isPath(layer)) return [];
        if ('category' in layer) {
            return layer.category;
        } else {
            return layer.components.map((component) => {
                return component.category;
            });
        }
    });

    const uniqueCategories = Array.from(new Set(allCategories));

    return (
        <>
            <div className="w-full">
                <div
                    className="h-full flex flex-col justify-between min-h-[250px] bg-white rounded-lg drop-shadow-sm cursor-pointer hover:bg-gray-100"
                    onClick={openModal}
                >
                    <div className="p-4">
                        <div className="flex flex-col relative">
                            {template.workflow_data.map((layer, idx) => {
                                const leftPositionIcon = 30 * idx;
                                const app = isPath(layer)
                                    ? 'path'
                                    : isAction(layer)
                                    ? layer.components?.[0].app
                                    : layer.app;
                                const logoSrc =
                                    process.env.PUBLIC_URL + `/${app}.png`;
                                return (
                                    <div
                                        className="absolute rounded-full p-2 bg-grey-light drop-shadow-md"
                                        style={{
                                            left: `${leftPositionIcon}px`,
                                        }}
                                    >
                                        <img
                                            alt={app}
                                            src={logoSrc}
                                            className="w-8 h-8"
                                        />
                                    </div>
                                );
                            })}
                            <div className="pt-16 text-lg-semibold">
                                {template.workflow_name}
                                <div className="pt-4">
                                    <p className="text-sm">
                                        {template.workflow_description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="border-b border-grey-shadow dark:border-grey-night" />
                        <div className="flex px-4 py-2 gap-1 flex-wrap">
                            {uniqueCategories.map((category) => {
                                return (
                                    <div className="px-3 py-1 rounded-2xl border bg-grey-light border-slate-200 drop-shadow-sm">
                                        <p className="text-xs">{category}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                                    <div
                                        className="absolute top-2 right-2 bg-gray-200 hover:bg-gray-300 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer transition-colors duration-300"
                                        onClick={closeModal}
                                    >
                                        x
                                    </div>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        {snakeToReadable(
                                            template.workflow_name,
                                        )}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className="flex justify-evenly">
                                            {template.workflow_data.flatMap(
                                                (
                                                    layer,
                                                    index: number,
                                                    array,
                                                ) => {
                                                    const app = isPath(layer)
                                                        ? 'path'
                                                        : isAction(layer)
                                                        ? layer.components?.[0]
                                                              .app
                                                        : layer.app;
                                                    const logoSrc =
                                                        process.env.PUBLIC_URL +
                                                        `/${app}.png`;
                                                    const name = isPath(layer)
                                                        ? 'multi Route'
                                                        : isAction(layer)
                                                        ? layer.components?.[0]
                                                              .name
                                                        : layer.name;
                                                    return [
                                                        <div
                                                            key={index}
                                                            className="flex flex-col p-4 items-center justify-center rounded-xl"
                                                        >
                                                            <img
                                                                src={logoSrc}
                                                                alt={app}
                                                                className="w-10 h-10 drop-shadow-sm"
                                                            />
                                                            <div className="flex justify-center pt-4">
                                                                {snakeToReadable(
                                                                    app,
                                                                )}
                                                            </div>
                                                            <div className="flex justify-center pt-2 text-sm">
                                                                {snakeToReadable(
                                                                    name,
                                                                )}
                                                            </div>
                                                        </div>,
                                                        ...(index ===
                                                        array.length - 1
                                                            ? []
                                                            : [
                                                                  <div
                                                                      key={`arrow-${index}`}
                                                                      className="flex justify-center items-center"
                                                                  >
                                                                      <ArrowRightIcon />
                                                                  </div>,
                                                              ]),
                                                    ];
                                                },
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <Button
                                            size="md"
                                            onClick={() =>
                                                handleClick(template)
                                            }
                                        >
                                            Use Template
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default TemplateElement;
