import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import {
    EnhancedAction,
    EnhancedListener,
    EnhancedWorkflow,
    Switch,
} from '../../../utils/types';
import { isEnhancedAction, isOverviewSwitch } from '../../../utils/helpers';

export const WorkflowOverviewWidget: React.FC<{
    workflows: EnhancedWorkflow[];
    loadingWorkflows: boolean;
    errorWorkflows: any;
}> = ({ workflows, loadingWorkflows, errorWorkflows }) => {
    const navigate = useNavigate();
    return (
            <div className="p-10">
                <p className="text-h3 text-slate-400">Your Workflows</p>
                {loadingWorkflows || errorWorkflows
                    ? null
                    : workflows?.map((workflow, index) => {
                          const leftPositionTitle = 150;
                          const totalIcons = workflow.workflow_data.length;
                          const defaultIconWidth = 40;
                          const requiredWidth = defaultIconWidth * totalIcons;

                          const iconWidth =
                              requiredWidth > leftPositionTitle
                                  ? (leftPositionTitle - 40) / totalIcons
                                  : defaultIconWidth;

                          return (
                              <div key={index} className="pt-6">
                                  <div
                                      className="p-6 bg-white rounded-lg text-h4 drop-shadow-md cursor-pointer hover:brightness-95"
                                      onClick={() => {
                                          navigate('/workflow-overview');
                                      }}
                                  >
                                      <div className="flex justify-between">
                                          <div className="relative flex items-center">
                                              {workflow.workflow_data.map(
                                                  (
                                                      layer:
                                                          | EnhancedListener
                                                          | EnhancedAction
                                                          | Switch,
                                                      idx: number,
                                                  ) => {
                                                      if (
                                                          isOverviewSwitch(
                                                              layer,
                                                          )
                                                      )
                                                          return [];
                                                      const leftPositionIcon =
                                                          iconWidth * idx;
                                                      return (
                                                          <div
                                                              key={idx}
                                                              className={cx(
                                                                  `absolute rounded-full p-2 bg-grey-cloudy drop-shadow-md`,
                                                              )}
                                                              style={{
                                                                  left: `${leftPositionIcon}px`,
                                                              }}
                                                          >
                                                              <img
                                                                  alt="Workflow Icons"
                                                                  src={
                                                                      process
                                                                          .env
                                                                          .PUBLIC_URL +
                                                                      `${
                                                                          isEnhancedAction(
                                                                              layer,
                                                                          )
                                                                              ? layer
                                                                                    ?.components?.[0]
                                                                                    .app
                                                                              : layer.app
                                                                      }.png`
                                                                  }
                                                                  className="w-8 h-8"
                                                              />
                                                          </div>
                                                      );
                                                  },
                                              )}
                                              <div
                                                  style={{
                                                      paddingLeft: `${leftPositionTitle}px`,
                                                  }}
                                              >
                                                  {workflow.workflow_name}
                                              </div>
                                          </div>
                                          {/* <div className="flex justify-evenly"> */}
                                          <div className="grid grid-cols-[2fr_auto_1fr] items-center gap-6 text-sm">
                                              <div className="flex gap-2">
                                                  <p>Created on:</p>
                                                  <p>
                                                      {new Date(
                                                          Date.parse(
                                                              workflow.creation_date,
                                                          ),
                                                      ).toLocaleDateString()}
                                                  </p>
                                              </div>
                                              <div className="h-full border-l border-grey-metal"></div>
                                              <div className="flex items-center gap-2 justify-end">
                                                  {workflow.failed ? (
                                                      <>
                                                          <div className="red-dot" />
                                                          <p className="mr-2">
                                                              Failed
                                                          </p>
                                                      </>
                                                  ) : workflow.active ? (
                                                      <>
                                                          <div className="green-dot" />
                                                          <p className="mr-2">
                                                              Active
                                                          </p>
                                                      </>
                                                  ) : (
                                                      <>
                                                          <div className="orange-dot" />
                                                          <p className="mr-2">
                                                              Done
                                                          </p>
                                                      </>
                                                  )}
                                              </div>
                                          </div>
                                          {/* </div> */}
                                      </div>
                                  </div>
                              </div>
                          );
                      })}
            </div>
    );
};
