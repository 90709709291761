import React, {
    useState,
    createContext,
    useContext,
    useCallback,
    ReactNode,
} from 'react';

import { WorkflowCountContextType } from '../utils/types/WorkflowCountTypes';

const WorkflowCountContext = createContext<WorkflowCountContextType | null>(
    null,
);

interface WorkflowCountProviderProps {
    children: ReactNode;
}

export const WorkflowCountProvider: React.FC<WorkflowCountProviderProps> = ({
    children,
}) => {
    const [workflowCount, setWorkflowCount] = useState<number>(0);

    const updateWorkflowCount = useCallback(
        (newCount: number | ((prevCount: number) => number)) => {
            setWorkflowCount(newCount);
        },
        [],
    );

    const contextValue: WorkflowCountContextType = {
        workflowCount,
        updateWorkflowCount,
    };

    return (
        <WorkflowCountContext.Provider value={contextValue}>
            {children}
        </WorkflowCountContext.Provider>
    );
};

export const useWorkflowCount = () => {
    const context = useContext(WorkflowCountContext);
    if (!context) {
        throw new Error(
            'useWorkflowCount must be used within a WorkflowCountProvider',
        );
    }
    return context;
};
