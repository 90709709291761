import * as React from 'react';

import { LoadingDots } from './LoadingDots';

import cx from 'classnames';

const FILLED_BUTTON_CLASSES = cx('text-snowWhite bg-violet dark:bg-peach');
const OUTLINE_BUTTON_CLASSES = cx('text-violet dark:text-peach');

export const DISABLED_BUTTON_CLASSES = cx(
    'disabled:cursor-not-allowed disabled:hover:shadow-none disabled:active:border-violet dark:disabled:active:border-peach',
);
export const FOCUS_BUTTON_CLASSES = cx(
    'focus:outline-none focus-visible:outline-offset-2 focus-visible:outline-violet-medium dark:focus-visible:outline-peach-medium',
);
export const HOVER_BUTTON_CLASSES = cx('hover:shadow-button-hover');
export const ACTIVE_BUTTON_CLASSES = cx(
    'active:border-violet-dark dark:active:border-peach-dark',
);

const BUTTON_CLASSES = {
    default: cx(
        'flex justify-center rounded-xl border-2 border-violet dark:border-peach py-1.5',
        FOCUS_BUTTON_CLASSES,
        HOVER_BUTTON_CLASSES,
        ACTIVE_BUTTON_CLASSES,
    ),
    sm: cx('text-info leading-3 font-normal px-1.5 rounded-lg'),
    md: cx('text-md leading-5 font-normal px-3'),
    lg: cx('text-lg leading-[2rem] font-medium px-[1.375rem]'),
    filled: FILLED_BUTTON_CLASSES,
    outline: OUTLINE_BUTTON_CLASSES,
    disabled: cx(DISABLED_BUTTON_CLASSES, 'disabled:opacity-30'),
    loading: cx(DISABLED_BUTTON_CLASSES, 'disabled:opacity-50'),
};

export type ButtonProps = Omit<
    React.ComponentPropsWithoutRef<'button'>,
    'disabled'
> & {
    className?: string;
    variant?: 'filled' | 'outline';
    size?: 'sm' | 'md' | 'lg';
    isDisabled?: boolean;
    isLoading?: boolean;
    isReadOnly?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => {
        const {
            isDisabled = false,
            isLoading = false,
            size = 'lg',
            variant = 'filled',
            className,
            children,
            isReadOnly,
            onClick,
            ...rest
        } = props;

        const classes = cx(
            className,
            BUTTON_CLASSES.default,
            BUTTON_CLASSES[variant],
            BUTTON_CLASSES[size],
            isDisabled && BUTTON_CLASSES.disabled,
            isLoading && BUTTON_CLASSES.loading,
        );

        return (
            <button
                ref={ref}
                aria-readonly={isReadOnly}
                className={classes}
                disabled={isDisabled || isLoading}
                onClick={isReadOnly ? undefined : onClick}
                {...rest}
            >
                <span
                    className={cx('relative inline-flex items-center gap-x-1')}
                >
                    {children}

                    {isLoading && <LoadingDots />}
                </span>
            </button>
        );
    },
);

Button.displayName = 'Button';
