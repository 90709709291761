import cx from 'classnames';

import { snakeToReadable } from '../../utils/helpers';

interface CategorySectionProps {
    selectedCategory: string;
    setSelectedCategory: (value: React.SetStateAction<string>) => void;
    uniqueCategories: string[];
}

const CategorySection: React.FC<CategorySectionProps> = ({
    selectedCategory,
    setSelectedCategory,
    uniqueCategories,
}) => {
    return (
        <div className="flex min-w-fit pl-10">
            <div className="border-l border-grey-shadow dark:border-grey-night" />
            <div className="pl-10">
                <p>Browse by Category</p>
                <ul className="text-xs pt-4 space-y-2">
                    <li
                        className={cx(
                            'cursor-pointer hover:text-gray-400',
                            selectedCategory === 'All'
                                ? 'text-blue-600 hover:text-blue-400'
                                : '',
                        )}
                        onClick={() => setSelectedCategory('All')}
                    >
                        All
                    </li>
                    {uniqueCategories?.map((category) => (
                        <li
                            key={category}
                            className={cx(
                                'cursor-pointer hover:text-gray-400',
                                selectedCategory === category
                                    ? 'text-blue-600 hover:text-blue-400'
                                    : '',
                            )}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {snakeToReadable(category || '')}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CategorySection;
