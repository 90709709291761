import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { Input } from '../Core/Input';
import { Button } from '../Core/Button';
import { debounce, snakeToReadable } from '../../utils/helpers';
import {
    CardInput,
    GateHelper,
    InputObject,
    InputObjectMap,
    Inputs,
    Integrations,
    Output,
    Task,
} from '../../utils/types';
import { IntegrationDialog, TaskDialog, WFCardListBox } from './includes';
import { useAuth } from '../../context/AuthContext';
import { AddIcon } from '../Icons/AddIcon';
import { CheckIcon } from '../Icons/CheckIcon';

interface CardProps {
    integrationData: Integrations[];
    cardData: CardInput;
    onAdd: (cardId: string) => void;
    onSave: (card: CardInput) => void;
    onDelete: (cardId: string) => void;
    first: boolean;
    hasSecond: boolean;
    last: boolean;
    onOpenDrawer: (
        card: CardInput,
        mandatoryInputs: { required: Inputs[]; optional: Inputs[] },
    ) => void;
    isInputValid: boolean[];
    setIsInputValid: React.Dispatch<
        React.SetStateAction<Record<string, boolean[]>>
    >;
    setRequiredInputObjects: React.Dispatch<
        React.SetStateAction<InputObjectMap>
    >;
    setOptionalInputObjects: React.Dispatch<
        React.SetStateAction<InputObjectMap>
    >;
    onCloseDrawer: () => void;
}

const WorkflowCreationCard: React.FC<CardProps> = ({
    integrationData,
    cardData,
    onAdd,
    onSave,
    onDelete,
    first,
    hasSecond,
    last,
    onOpenDrawer,
    isInputValid,
    setIsInputValid,
    setRequiredInputObjects,
    setOptionalInputObjects,
    onCloseDrawer,
}) => {
    const {
        authState,
        handleDiscordLogin,
        handleGmailLogin,
        checkDiscordAccess,
        checkGmailAccess,
    } = useAuth();

    const [showConnectButton, setShowConnectButton] = useState<boolean | null>(
        null,
    );

    const [integration, setIntegration] = useState<Integrations>(() => {
        return (
            integrationData.find(
                (int) => int.app === cardData?.integrationName,
            ) || { app: '', description: '', sign_up: false, tasks: [] }
        );
    });

    const [task, setTask] = useState<Task>(() => {
        const initialIntegration = integrationData.find(
            (int) => int.app === cardData?.integrationName,
        );
        return (
            initialIntegration?.tasks.find(
                (task) => task.name === cardData?.taskName,
            ) || {
                name: '',
                description: '',
                inputs: { required_inputs: [], optional_inputs: [] },
                outputs: [],
                type: 'listener',
                contract_permissions: false,
            }
        );
    });

    const [gateHelper, setGateHelper] = useState<GateHelper[]>([]);

    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const [ownOutputs, setOwnOutputs] = useState<Output[]>([]);
    useMemo(() => {
        if (!task.inputs.required_inputs) return;
        const ownOutputsHelper: Output[] = [];
        task.outputs.forEach((output: string[], index: number) => {
            ownOutputsHelper.push({
                id: cardData.cardId + index,
                outputElement: output[0],
                processedOutput: [cardData.cardId, 'outputs', index],
                layerId: cardData.layerId,
            });
        });
        setOwnOutputs(ownOutputsHelper);
    }, [task, cardData.cardId]);

    function handleIntegrationSelection(selectedIntegrationName: string) {
        const selectedIntegration = integrationData?.find(
            (integration) => integration.app === selectedIntegrationName,
        );

        if (!selectedIntegration) return;

        if (selectedIntegrationName !== integration.app) {
            setTask({
                name: '',
                description: '',
                inputs: { required_inputs: [], optional_inputs: [] },
                outputs: [],
                type: 'listener',
                contract_permissions: false,
            });
            // setRequiredInputs([]);
            // setOptionalInputs([]);
        }
        setIntegration(selectedIntegration);
    }

    function handleTaskSelection(selectedTaskName: string) {
        if (!integration) {
            return { required_inputs: [], optional_inputs: [] };
        }

        const selectedTask = integration.tasks.find(
            (task) => task.name === selectedTaskName,
        );

        if (!selectedTask) return;

        if (selectedTask.inputs.required_inputs.length === 0) {
            setIsInputValid((prevState) => ({
                ...prevState,
                [cardData.cardId]: [],
            }));
        } else {
            setIsInputValid((prevState) => {
                const { [cardData.cardId]: _, ...newState } = prevState;
                return newState;
            });
        }
        // setRequiredInputs([]);
        // setOptionalInputs([]);

        setRequiredInputObjects((prevState) => {
            const newInputs: InputObject[] =
                selectedTask.inputs.required_inputs.map((input) => ({
                    value: '',
                    placeholder: input.description,
                    ...(input.options && { options: input.options }),
                }));
            return { ...prevState, [cardData.cardId]: newInputs };
        });
        setOptionalInputObjects((prevState) => {
            const newInputs: InputObject[] =
                selectedTask.inputs.optional_inputs.map((input) => ({
                    value: '',
                    placeholder: input.description,
                }));
            return { ...prevState, [cardData.cardId]: newInputs };
        });

        setTask(selectedTask);
    }

    useEffect(() => {
        const cardInput = {
            cardId: cardData?.cardId,
            layerId: cardData.layerId,
            integrationName: integration.app,
            taskName: task?.name,
            inputs: cardData.inputs || [],
            oneTime: false,
            taskType: task.type,
            gateHelper,
        };
        onSave(cardInput);
    }, [cardData?.cardId, integration, task, gateHelper]);

    const handleAdd = () => {
        onAdd(cardData.cardId);
    };

    const handleDelete = () => {
        onDelete(cardData.cardId);
    };

    function handleAddCardSwitch() {
        setGateHelper((prev) => {
            return [
                ...prev,
                {
                    inputs: [],
                    data_type: '',
                    selectedIf: ownOutputs?.[0],
                    selectedIs: '<',
                    selectedThan: '',
                    selectedLayer: '',
                    catch: null,
                },
            ];
        });
    }

    interface LoginHandlers {
        discord: () => void;
        gmail: () => void;
    }

    const loginHandlers: LoginHandlers = {
        discord: handleDiscordLogin,
        gmail: handleGmailLogin,
    };

    const renderConnectButton = () => {
        const handleLogin =
            integration.app in loginHandlers
                ? loginHandlers[integration.app as keyof LoginHandlers]
                : () => {
                      console.error('No handler for this integration');
                  };

        return (
            <div className="p-2">
                <Button size={'md'} onClick={handleLogin} className="w-full">
                    Connect
                </Button>
            </div>
        );
    };

    useEffect(() => {
        const checkAccess = async () => {
            if (integration.app === 'gmail') {
                const response = await checkGmailAccess();
                setShowConnectButton(!response);
            }

            if (integration.app === 'discord') {
                const response = await checkDiscordAccess();
                setShowConnectButton(!response);
            }
        };

        setShowConnectButton(true);
        checkAccess();
    }, [integration.app]);

    useEffect(() => {
        setShowConnectButton(!authState.gmail.hasAccess);
    }, [authState.gmail.isLoading]);

    useEffect(() => {
        setShowConnectButton(!authState.discord.hasAccess);
    }, [authState.discord.isLoading]);

    const cardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (last && cardRef.current) {
            cardRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'end',
            });
        }
    }, [last]);

    const mandatoryInputs = {
        required: task.inputs?.required_inputs,
        optional: task.inputs?.optional_inputs,
    };

    // ! Hier werden options assigned
    function handleOpenDrawer() {
        onOpenDrawer(cardData, mandatoryInputs);
    }

    const checkIfScrolledToEnd = useCallback(() => {
        const element = scrollContainerRef.current;
        if (element) {
            const maxScrollLeft = element.scrollWidth - element.clientWidth;
            const isAtEnd = element.scrollLeft + 1 >= maxScrollLeft;
            setIsScrolledToEnd(isAtEnd);
        }
    }, []);

    const debouncedCheckIfScrolledToEnd = debounce(checkIfScrolledToEnd, 300);

    useEffect(() => {
        const element = scrollContainerRef.current;
        if (element) {
            element.addEventListener('scroll', checkIfScrolledToEnd);

            checkIfScrolledToEnd();
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', checkIfScrolledToEnd);
            }
        };
    }, [debouncedCheckIfScrolledToEnd]);

    return (
        <div
            ref={cardRef}
            className={`flex items-center ${last && 'pr-[36rem]'}`}
        >
            {!first && (
                <div className="flex items-center">
                    <div className="border-b border-primary-low w-4"></div>
                </div>
            )}
            <div className="flex-col w-[400px] justify-center p-6 space-y-6 drop-shadow-md bg-white rounded-xl">
                <div>
                    <div className="flex absolute left-1 top-1 space-x-2">
                        {task?.name !== '' && (
                            <div className="rounded-xl p-2 bg-primary-low text-xs text-white drop-shadow-md">
                                {task?.type === 'listener'
                                    ? 'Listener'
                                    : 'Action'}
                            </div>
                        )}
                    </div>
                    <div className="flex absolute right-1 top-1 space-x-2">
                        {(!first || hasSecond) && (
                            <div
                                className="rounded-xl p-2 bg-primary-low text-xs text-white drop-shadow-md cursor-pointer"
                                onClick={handleDelete}
                            >
                                <p className="px-1">X</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="pb-6">
                    <IntegrationDialog
                        integrations={integrationData}
                        onSelection={handleIntegrationSelection}
                        preselection={cardData.integrationName || null}
                    />
                    <TaskDialog
                        selectedIntegration={integration}
                        onSelection={handleTaskSelection}
                        preselection={cardData.taskName || null}
                    />
                    {integration.sign_up &&
                    (task.name === 'discord_send' ||
                        task.name === 'gmail_send') &&
                    showConnectButton ? (
                        <>{renderConnectButton()}</>
                    ) : (
                        <>
                            {(task?.inputs?.required_inputs?.length > 0 ||
                                task.inputs.optional_inputs?.length > 0) && (
                                <div>
                                    <div
                                        className={`relative w-full flex p-2 justify-between items-center rounded-lg cursor-pointer hover:bg-slate-100`}
                                        onClick={() => handleOpenDrawer()}
                                    >
                                        {isInputValid.includes(false) && (
                                            <div className="absolute top-0 right-0">
                                                <ExclamationCircleIcon className="w-6 h-6 text-action-red" />
                                            </div>
                                        )}
                                        <p>Inputs:</p>
                                        {mandatoryInputs.required.length ===
                                        0 ? (
                                            <div>
                                                <AddIcon className="w-9 h-9 text-grey-metal" />
                                            </div>
                                        ) : isInputValid.length > 0 &&
                                          !isInputValid.includes(false) ? (
                                            <div>
                                                <CheckIcon className="w-9 h-9 text-action-green" />
                                            </div>
                                        ) : (
                                            <div
                                                className={cx(
                                                    'flex items-center',
                                                    'text-primary',
                                                    'rounded-lg',
                                                )}
                                            >
                                                <AddIcon className="w-9 h-9" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {ownOutputs.length > 0 && (
                        <>
                            <div className="flex px-2 py-4 items-center justify-center">
                                <div className="flex-1 border-t border-gray-300"></div>
                                <div className="mx-3 bg-white rounded-full w-5 h-5 flex items-center justify-center border border-gray-300">
                                    <ChevronDownIcon className="text-gray-300 w-4 h-4" />
                                </div>
                                <div className="flex-1 border-t border-gray-300"></div>
                            </div>
                            <div className="p-2">
                                <div className="flex justify-between">
                                    <p>Generated output:</p>
                                    <Button
                                        className="w-fit !border !border-gray-300 !text-gray-400 hover:!text-gray-500 hover:!border-gray-400"
                                        variant="outline"
                                        size="sm"
                                        onClick={handleAddCardSwitch}
                                    >
                                        + add conditional logic
                                    </Button>
                                </div>

                                <div className="relative flex pt-2">
                                    <div
                                        ref={scrollContainerRef}
                                        className="flex gap-2 overflow-x-auto scrollbar-hide"
                                    >
                                        {ownOutputs.map((output, index) => (
                                            <div
                                                key={index}
                                                className="px-4 py-1 text-sm bg-white border rounded-lg"
                                            >
                                                {snakeToReadable(
                                                    output.outputElement,
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {!isScrolledToEnd && (
                                        <div className="absolute top-0 right-0 h-full w-12 pointer-events-none bg-gradient-to-r from-transparent to-white" />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    <div>
                        {task.name !== '' && ownOutputs.length > 0 && (
                            <div className="flex flex-col pt-2 px-2">
                                {gateHelper.length === 0 ? null : (
                                    <div>
                                        {gateHelper.map((s, idx) => {
                                            return (
                                                <div className="grid grid-cols-3 gap-x-4 pb-4 relative z-10">
                                                    <div>
                                                        <p>If...</p>
                                                        <WFCardListBox
                                                            selected={
                                                                s.selectedIf
                                                                    .outputElement
                                                            }
                                                            setSelected={(
                                                                value,
                                                            ) =>
                                                                setGateHelper(
                                                                    (
                                                                        prevGate,
                                                                    ) =>
                                                                        prevGate.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) =>
                                                                                index ===
                                                                                idx
                                                                                    ? {
                                                                                          ...item,
                                                                                          selectedIf:
                                                                                              ownOutputs.find(
                                                                                                  (
                                                                                                      output,
                                                                                                  ) =>
                                                                                                      output.outputElement ===
                                                                                                      value,
                                                                                              )!,
                                                                                      }
                                                                                    : item,
                                                                        ),
                                                                )
                                                            }
                                                            options={ownOutputs.flatMap(
                                                                (output) =>
                                                                    output.outputElement,
                                                            )}
                                                        />
                                                    </div>
                                                    <div>
                                                        <p>...is...</p>
                                                        <WFCardListBox
                                                            selected={
                                                                s.selectedIs
                                                            }
                                                            setSelected={(
                                                                value,
                                                            ) =>
                                                                setGateHelper(
                                                                    (
                                                                        prevGate,
                                                                    ) =>
                                                                        prevGate.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) =>
                                                                                index ===
                                                                                idx
                                                                                    ? {
                                                                                          ...item,
                                                                                          selectedIs:
                                                                                              value,
                                                                                      }
                                                                                    : item,
                                                                        ),
                                                                )
                                                            }
                                                            options={[
                                                                '<',
                                                                '>',
                                                                '<=',
                                                                '>=',
                                                                '==',
                                                                '!=',
                                                            ]}
                                                        />
                                                    </div>
                                                    <div>
                                                        <p>...than...</p>
                                                        <Input
                                                            aria-label="Conditional Logic Value"
                                                            placeholder="Insert value"
                                                            value={
                                                                s.selectedThan
                                                            }
                                                            onChange={(e) => {
                                                                setGateHelper(
                                                                    (
                                                                        prevGate,
                                                                    ) =>
                                                                        prevGate.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) =>
                                                                                index ===
                                                                                idx
                                                                                    ? {
                                                                                          ...item,
                                                                                          selectedThan:
                                                                                              e,
                                                                                      }
                                                                                    : item,
                                                                        ),
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex items-center">
                <div className="border-b border-primary-low w-4"></div>
                <button
                    onClick={handleAdd}
                    className="w-6 h-6 border border-primary-low rounded-full flex items-center justify-center text-primary-low"
                >
                    +
                </button>
            </div>
        </div>
    );
};

export default WorkflowCreationCard;
