import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getAccessToken } from '@privy-io/react-auth';

export function useFetchEndpoint<T = any>(
    endpointUrl: string,
    responseKey?: string,
): {
    data: T | null;
    loading: boolean;
    error: any;
    refetch: () => void;
} {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [trigger, setTrigger] = useState<number>(0); // New state to trigger re-fetch

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const authToken = await getAccessToken();
                const response = await axios.get(endpointUrl, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setData(
                    responseKey ? response.data[responseKey] : response.data,
                );
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [endpointUrl, responseKey, trigger]); // Include trigger in the dependency array

    const refetch = useCallback(() => {
        setTrigger((prev) => prev + 1); // Increment trigger to re-run the effect
    }, []);

    return { data, loading, error, refetch };
}
