import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Integrations } from '../../../utils/types';
import { snakeToReadable } from '../../../utils/helpers';

import cx from 'classnames';
import { AddIcon } from '../../Icons/AddIcon';

export const IntegrationDialog = ({
    integrations,
    onSelection,
    preselection,
}: {
    integrations: Integrations[];
    onSelection: (name: string) => void;
    preselection: string | null;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | null>(null);

    useEffect(() => {
        if (preselection !== null) {
            setSelected(preselection);
        }
    }, []);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function handleSelection(name: string) {
        setSelected(name);
        closeModal();
        onSelection(name);
    }

    return (
        <>
            <div
                className={`w-full flex p-2 justify-between items-center rounded-lg cursor-pointer group hover:bg-slate-100`}
                onClick={openModal}
            >
                <p>{'Integration:'}</p>
                {selected != null ? (
                    <div>
                        <div
                            className={cx(
                                'flex gap-2 py-1 px-2',
                                'text-md text-grey-night',
                                'bg-white border rounded-lg cursor-pointer group-hover:bg-slate-50',
                            )}
                        >
                            <img
                                alt="Integration icon"
                                className="w-6 h-6"
                                src={process.env.PUBLIC_URL + `${selected}.png`}
                            />
                            {snakeToReadable(selected)}
                        </div>
                    </div>
                ) : (
                    <div
                        className={cx(
                            'flex items-center',
                            'text-primary',
                            'rounded-lg',
                        )}
                    >
                        <AddIcon className="w-9 h-9" />
                    </div>
                )}
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        {`Select an Integration`}
                                    </Dialog.Title>
                                    <div className="mt-10">
                                        <div className="grid grid-cols-4 gap-2">
                                            {integrations?.map(
                                                (
                                                    integration,
                                                    index: number,
                                                ) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`flex flex-col items-center gap-4 py-6 hover:bg-slate-100 rounded-xl cursor-pointer ${
                                                                integration.app ===
                                                                selected
                                                                    ? 'bg-slate-100'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                handleSelection(
                                                                    integration.app,
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                alt="Integration icon"
                                                                className="w-12 h-12"
                                                                src={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    `${integration.app}.png`
                                                                }
                                                            />
                                                            <p>
                                                                {snakeToReadable(
                                                                    integration.app,
                                                                )}
                                                            </p>
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
