import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import {
    isEnhancedAction,
    isOverviewSwitch,
    snakeToReadable,
} from '../../utils/helpers';
import { Button } from '../Core/Button';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon';
import { ArrowLeftIcon } from '../Icons/ArrowLeftIcon';
import '../../styles/WorkflowCreation.css';

import {
    EnhancedAction,
    EnhancedListener,
    EnhancedWorkflow,
    Integrations,
} from '../../utils/types';
import { useBreakpoint } from '../../hooks/Core/useBreakpoint';
import { TrashIcon } from '../Icons/TrashIcon';
import { EditIcon } from '../Icons/EditIcon';
import { StartIcon } from '../Icons/StartIcon';
import { PauseIcon } from '../Icons/PauseIcon';

interface WorkflowElementProps {
    workflow: EnhancedWorkflow;
    filteredIntegrations: Integrations[];
    handleResume: (curr_id: number) => void; // TODO: Fill in curr_id in parent component
    handlePause: (curr_id: number) => void;
    handleDelete: (curr_id: number) => void;
    handleEditWorkflow: () => void;
}

const WorkflowElement: React.FC<WorkflowElementProps> = ({
    workflow,
    filteredIntegrations,
    handleResume,
    handlePause,
    handleDelete,
    handleEditWorkflow,
}) => {
    const [selectedTaskElement, setSelectedTaskElement] = useState<
        EnhancedListener | EnhancedAction | null
    >(null);

    const leftPositionTitle = 150;
    const totalIcons = workflow.workflow_data.length;
    const defaultIconWidth = 40;
    const requiredWidth = defaultIconWidth * totalIcons;

    const iconWidth =
        requiredWidth > leftPositionTitle
            ? (leftPositionTitle - 40) / totalIcons
            : defaultIconWidth;
    const currentBreakpoint = useBreakpoint();

    function logoBreakpoint(): number {
        return currentBreakpoint === 'xs'
            ? 2
            : currentBreakpoint === 'sm'
            ? 3
            : currentBreakpoint === 'md'
            ? 4
            : currentBreakpoint === 'lg'
            ? 4
            : currentBreakpoint === 'xl'
            ? 5
            : currentBreakpoint === 'xxl'
            ? 6
            : 20;
    }

    function RenderWorkflowVisual() {
        return (
            <div
                className={`flex ${
                    workflow.workflow_data.length > logoBreakpoint()
                        ? 'justify-start'
                        : 'justify-evenly'
                } overflow-y-hidden overflow-x-visible`}
            >
                {workflow.workflow_data.flatMap(
                    (layer, index: number, array) => {
                        if (isOverviewSwitch(layer)) return [];

                        const appName = isEnhancedAction(layer)
                            ? layer.components?.[0].app
                            : layer.app;
                        const logoSrc =
                            process.env.PUBLIC_URL + `/${appName}.png`;
                        return [
                            <div
                                key={index}
                                className="flex flex-col p-4 items-center justify-center cursor-pointer hover:bg-gray-100 rounded-xl"
                                onClick={() => setSelectedTaskElement(layer)}
                            >
                                <img
                                    src={logoSrc}
                                    alt={appName}
                                    className="w-10 h-10 drop-shadow-sm"
                                />
                                <div className="flex justify-center pt-4">
                                    {snakeToReadable(appName)}
                                </div>
                                <div className="flex justify-center pt-2 text-sm">
                                    {snakeToReadable(
                                        isEnhancedAction(layer)
                                            ? layer.components?.[0]?.name
                                            : layer.name,
                                    )}
                                </div>
                            </div>,
                            ...(index === array.length - 1
                                ? []
                                : [
                                      <div
                                          key={`arrow-${index}`}
                                          className="flex justify-center items-center"
                                      >
                                          <ArrowRightIcon />
                                      </div>,
                                  ]),
                        ];
                    },
                )}
            </div>
        );
    }

    function RenderNoTaskSelected() {
        return (
            <div className="h-full flex flex-col justify-between overflow-auto">
                <div className="text-xs space-y-1">
                    {workflow.failed ? (
                        <div className="flex gap-6">
                            <p className="shrink-0">Failure reason:</p>
                            <p className="text-action-red">
                                {workflow.failed_msg}
                            </p>
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 gap-8">
                            <div className="grid grid-cols-2">
                                <p>Creation date:</p>
                                <p>
                                    {new Date(
                                        Date.parse(workflow.creation_date),
                                    ).toLocaleDateString()}
                                </p>
                            </div>
                            <div className="grid grid-cols-2">
                                <p>Running since:</p>
                                <p>
                                    {workflow.running_since
                                        ? new Date(
                                              Date.parse(
                                                  workflow.running_since,
                                              ),
                                          ).toLocaleDateString()
                                        : 'N/A'}
                                </p>
                            </div>
                        </div>
                    )}
                    {Object.keys(workflow.last_run).length > 0 && (
                        <div className="grid grid-cols-2 gap-8">
                            <div className="grid grid-cols-2">
                                <p>Last finish:</p>
                                <p>
                                    {workflow.last_run.last_run_time.slice(
                                        0,
                                        -3,
                                    )}
                                </p>
                            </div>
                            <div className="grid grid-cols-2">
                                <p>Total runs:</p>
                                <p>{workflow.num_runs}</p>
                            </div>
                        </div>
                    )}
                </div>
                {Object.keys(workflow.last_run).length === 0 ? null : (
                    <div className="py-2 mt-2 px-4 border rounded-md">
                        <p className="pb-2 text-sm text-grey-elephant">
                            Last run
                        </p>
                        <div className="grid grid-cols-2 gap-5">
                            <RenderIONoTaskSelected
                                data={workflow.last_run.data}
                                isInput={true}
                            />
                            <RenderIONoTaskSelected
                                data={workflow.last_run.data}
                                isInput={false}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    function RenderIONoTaskSelected({
        data,
        isInput,
    }: {
        data: {
            [key: string]: {
                inputs: (string | number | { data: string[] })[];
                outputs: (string | number | { data: string[] })[];
            };
        };
        isInput: boolean;
    }) {
        return (
            <div>
                <div className="flex px-2 py-1 bg-grey-cloudy rounded-md text-sm text-grey-metal">
                    {isInput ? 'INPUTS' : 'OUTPUTS'}
                </div>
                <div className="flex flex-col pt-2 space-y-2 max-h-[4.5rem] overflow-auto">
                    {Object.entries(data).map(([component_id, data]) => {
                        const correspondingComponent =
                            workflow.workflow_data.find((comp) => {
                                if (isOverviewSwitch(comp)) return false;
                                return isEnhancedAction(comp)
                                    ? comp.components?.[0].component_id ===
                                          component_id
                                    : comp.component_id === component_id;
                            });

                        if (
                            !correspondingComponent ||
                            isOverviewSwitch(correspondingComponent)
                        )
                            return null;

                        const IOdata = isInput ? data.inputs : data.outputs;

                        return (
                            <div
                                key={component_id}
                                className="flex space-x-4 text-xs"
                            >
                                <div className="flex flex-col space-y-1">
                                    {IOdata.length === 0 ? (
                                        <div className="flex gap-1">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    `${
                                                        isEnhancedAction(
                                                            correspondingComponent,
                                                        )
                                                            ? correspondingComponent
                                                                  .components?.[0]
                                                                  .app
                                                            : correspondingComponent.app
                                                    }.png`
                                                }
                                                alt="NoInputs"
                                                className="w-4 h-4 drop-shadow-sm"
                                            />
                                            <span>
                                                No{' '}
                                                {isInput ? 'inputs' : 'outputs'}{' '}
                                                set
                                            </span>
                                        </div>
                                    ) : (
                                        IOdata.map((IO, index) => {
                                            const appName = isEnhancedAction(
                                                correspondingComponent,
                                            )
                                                ? correspondingComponent
                                                      .components?.[0].app
                                                : correspondingComponent.app;
                                            const logoSrc =
                                                process.env.PUBLIC_URL +
                                                `/${appName}.png`;

                                            return (
                                                <div
                                                    key={index}
                                                    className="flex gap-1"
                                                >
                                                    <img
                                                        src={logoSrc}
                                                        alt={appName}
                                                        className="w-4 h-4 drop-shadow-sm"
                                                    />
                                                    {typeof IO === 'object' &&
                                                    'data' in IO
                                                        ? IO.data.map(
                                                              (
                                                                  entry,
                                                                  innerIdx,
                                                              ) => (
                                                                  <div
                                                                      key={
                                                                          innerIdx
                                                                      }
                                                                  >
                                                                      {entry.toString()}
                                                                  </div>
                                                              ),
                                                          )
                                                        : IO.toString()}
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    function RenderTaskSelected({
        selected,
    }: {
        selected: EnhancedAction | EnhancedListener;
    }) {
        const selectedListenerAction = isEnhancedAction(selected)
            ? selected.components?.[0]
            : selected;

        return (
            <>
                <div className="flex gap-2">
                    <ArrowLeftIcon
                        onClick={() => setSelectedTaskElement(null)}
                        className="w-6 h-6 hover:bg-gray-100 text-grey-metal rounded-md"
                    />
                    <p>{snakeToReadable(selectedListenerAction.app)}</p>
                </div>
                <div className="pt-2 pl-2 text-xs">
                    {filteredIntegrations.find((integration) =>
                        integration.tasks.find(
                            (task) => task.name === selectedListenerAction.name,
                        ),
                    )?.description || null}
                </div>
                <div className="grid grid-cols-2 pt-4 gap-5">
                    <RenderIOTaskSelected selected={selected} isInput={true} />
                    <RenderIOTaskSelected selected={selected} isInput={false} />
                </div>
            </>
        );
    }

    function RenderIOTaskSelected({
        selected,
        isInput,
    }: {
        selected: EnhancedAction | EnhancedListener;
        isInput: boolean;
    }) {
        const selectedListenerAction = isEnhancedAction(selected)
            ? selected.components?.[0]
            : selected;

        const selectedIO = isInput
            ? selectedListenerAction.recent_io.inputs
            : selectedListenerAction.recent_io.outputs;

        return (
            <div>
                <div className="flex p-2 bg-grey-cloudy rounded-md text-sm text-grey-metal">
                    {isInput ? 'INPUTS' : 'OUTPUTS'}
                </div>
                <div className="flex flex-col pt-2 space-y-2 max-h-[5rem] overflow-auto">
                    {selectedIO.map((IO, idx) => {
                        return (
                            <div key={idx} className="text-xs">
                                {typeof IO === 'object' && 'data' in IO
                                    ? IO.data.map(
                                          (entry: string, innerIdx: number) => (
                                              <div
                                                  key={innerIdx}
                                                  className="pt-2"
                                              >
                                                  {entry.toString()}
                                              </div>
                                          ),
                                      )
                                    : IO.toString()}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        <div className="bg-white rounded-xl drop-shadow-md pb-1">
            <Disclosure>
                {({ open }) => (
                    <>
                        <div className="grid xl:grid-cols-[3fr_1fr] lg:grid-cols-[2fr_1fr] grid-cols-[1fr_1fr] p-4 items-center">
                            <Disclosure.Button className="flex rounded-lg mr-4 py-2">
                                <ChevronRightIcon
                                    className={`${
                                        open ? 'rotate-90 transform' : ''
                                    } h-6 w-6 mr-1 text-blue-600`}
                                />
                                <div className="relative flex items-center">
                                    {workflow.workflow_data.map(
                                        (layer, idx) => {
                                            const leftPositionIcon =
                                                4 + iconWidth * idx;
                                            if (isOverviewSwitch(layer))
                                                return [];
                                            return (
                                                <div
                                                    key={idx}
                                                    className="absolute rounded-full p-2 bg-grey-cloudy drop-shadow-md"
                                                    style={{
                                                        left: `${leftPositionIcon}px`,
                                                    }}
                                                >
                                                    <img
                                                        alt="Workflow Icons"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            `${
                                                                isEnhancedAction(
                                                                    layer,
                                                                )
                                                                    ? layer
                                                                          ?.components?.[0]
                                                                          .app
                                                                    : layer.app
                                                            }.png`
                                                        }
                                                        className="w-8 h-8"
                                                    />
                                                </div>
                                            );
                                        },
                                    )}
                                    <div
                                        style={{
                                            paddingLeft: `${leftPositionTitle}px`,
                                        }}
                                    >
                                        {workflow.workflow_name}
                                    </div>
                                </div>
                            </Disclosure.Button>
                            <div className="flex justify-between">
                                <div className="flex items-center gap-3">
                                    {workflow.failed ? (
                                        <>
                                            <div className="red-dot" />
                                            <p className="mr-2">Failed</p>
                                        </>
                                    ) : workflow.active ? (
                                        <>
                                            <div className="green-dot" />
                                            <p className="mr-2">Active</p>
                                        </>
                                    ) : (
                                        <>
                                            <div className="orange-dot" />
                                            <p className="mr-2">Done</p>
                                        </>
                                    )}
                                </div>
                                <div className="flex items-center gap-3">
                                    {!workflow.failed && (
                                        <>
                                            {!workflow.active ? (
                                                <div
                                                    onClick={() =>
                                                        handleResume(
                                                            workflow.curr_id,
                                                        )
                                                    }
                                                    className="group p-3 rounded-xl hover:bg-gray-100 hover:cursor-pointer"
                                                >
                                                    <StartIcon className="w-6 h-6 group-hover:text-grey-night" />
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() =>
                                                        handlePause(
                                                            workflow.curr_id,
                                                        )
                                                    }
                                                    className="group p-3 rounded-xl hover:bg-gray-100 hover:cursor-pointer"
                                                >
                                                    <PauseIcon className="w-6 h-6 group-hover:text-grey-night" />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <div
                                        onClick={handleEditWorkflow}
                                        className="group p-3 rounded-xl hover:bg-gray-100 hover:cursor-pointer"
                                    >
                                        <EditIcon className="w-6 h-6 group-hover:text-grey-night" />
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleDelete(workflow.curr_id)
                                        }
                                        className="group p-3 rounded-xl hover:bg-gray-100 hover:cursor-pointer"
                                    >
                                        <TrashIcon className="w-6 h-6 group-hover:text-grey-night" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Disclosure.Panel>
                            {/* Divider */}
                            <div className="border-b border-grey-shadow dark:border-grey-night" />
                            <div className="grid grid-cols-2 p-4 space-x-8 min-h-[18rem] max-h-[18rem]">
                                <div className="flex flex-col">
                                    <div className="p-4 bg-grey-cloudy rounded-md">
                                        <p className="text-grey-metal">
                                            VISUAL
                                        </p>
                                    </div>
                                    <div className="pt-4">
                                        <RenderWorkflowVisual />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="p-4 bg-grey-cloudy text-grey-metal rounded-md">
                                        {selectedTaskElement === null
                                            ? 'WORKFLOW STATS'
                                            : 'TASK STATS'}
                                    </div>
                                    <div className="pt-4">
                                        <div className="flex-col pb-4">
                                            {selectedTaskElement === null ? (
                                                <RenderNoTaskSelected />
                                            ) : (
                                                <RenderTaskSelected
                                                    selected={
                                                        selectedTaskElement
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};

export default WorkflowElement;
