import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { usePrivy } from '@privy-io/react-auth';
import { useWorkflowCount } from '../context/WorkflowCountContext';
import { Integrations } from '../utils/types';

export function useWorkflowManagement() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const { getAccessToken } = usePrivy();
    const { updateWorkflowCount } = useWorkflowCount();

    const [integrations, setIntegrations] = useState<Integrations[]>([]);
    const [workflows, setWorkflows] = useState([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useCallback(async () => {
        setIsLoading(true);
        const authToken = await getAccessToken();
        try {
            const response = await axios.get(`${baseURL}/app/integrations/`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setIntegrations(response.data.integrations);
        } catch (error) {
            console.error('Error fetching integrations:', error);
        } finally {
            setIsLoading(false);
        }
    }, [baseURL, getAccessToken]);

    const fetchWorkflows = useCallback(async () => {
        setIsLoading(true);
        const authToken = await getAccessToken();
        try {
            const response = await axios.get(
                `${baseURL}/app/workflow-overview/`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            );
            setWorkflows(response.data.all_workflows);
            updateWorkflowCount(
                response.data.all_workflows.filter(
                    (workflow: any) => workflow.active === true,
                ).length,
            );
        } catch (error) {
            toast.error(`Error fetching workflows: ${error}`);
        } finally {
            setIsLoading(false);
        }
    }, [baseURL, getAccessToken, setWorkflows, updateWorkflowCount]);

    const handlePause = async (workflowIdInput: number) => {
        setIsLoading(true);
        const workflowId = workflowIdInput;
        if (isNaN(workflowId)) {
            toast.error('Invalid workflow ID');
            return;
        }

        const authToken = await getAccessToken();

        try {
            await axios.post(
                `${baseURL}/app/workflow-overview/`,
                { action: 'pause', workflow_id: workflowIdInput },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            );
            fetchWorkflows();
        } catch (error) {
            toast.error(`Error pausing workflow: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleResume = async (workflowIdInput: number) => {
        setIsLoading(true);
        const workflowId = workflowIdInput;
        if (isNaN(workflowId)) {
            toast.error('Invalid workflow ID');
            return;
        }

        const authToken = await getAccessToken();

        try {
            await axios.post(
                `${baseURL}/app/workflow-overview/`,
                { action: 'resume', workflow_id: workflowIdInput },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            );
            fetchWorkflows();
        } catch (error) {
            toast.error(`Error resuming workflow: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (workflowIdInput: number) => {
        setIsLoading(true);
        const workflowId = workflowIdInput;
        if (isNaN(workflowId)) {
            toast.error('Invalid workflow ID');
            return;
        }

        const authToken = await getAccessToken();

        try {
            await axios.post(
                `${baseURL}/app/workflow-overview/`,
                { action: 'delete', workflow_id: workflowIdInput },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            );
            fetchWorkflows();
        } catch (error) {
            toast.error(`Error deleting workflow: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchWorkflows();
    }, [fetchWorkflows]);

    return {
        isLoading,
        integrations,
        workflows,
        fetchWorkflows,
        handlePause,
        handleResume,
        handleDelete,
    };
}
