import cx from 'classnames';
import type { IContentLoaderProps } from 'react-content-loader';
import ContentLoader from 'react-content-loader';

const SkeletonLoader: React.FC<IContentLoaderProps> = ({
    children,
    ...props
}) => (
    <ContentLoader
        speed={2}
        backgroundColor="#bec3ca"
        foregroundColor="#d4d7dc"
        className={cx('opacity-40')}
        {...props}
    >
        {children}
    </ContentLoader>
);

export default SkeletonLoader;
