import {
    Action,
    EnhancedAction,
    EnhancedListener,
    Switch,
    Listener,
} from './types';

export function snakeToReadable(str: string) {
    return str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

// Type guard for Action
export function isAction(object: Listener | Action): object is Action {
    return 'components' in object;
}

export function isGate(object: Switch | Listener | Action): object is Switch {
    return 'switch_logic' in object;
}

export function isPath(object: Switch | Listener | Action): object is Switch {
    return 'switch_logic' in object;
}

export function isEnhancedGate(
    object: Switch | EnhancedListener | EnhancedAction,
): object is Switch {
    return 'switch_logic' in object;
}

export function isNumber(value: any): boolean {
    const hexPattern = /^0x[a-fA-F0-9]+$/;
    return (
        !hexPattern.test(value) &&
        !isNaN(Number(value)) &&
        !isNaN(parseFloat(value))
    );
}

export function isEnhancedAction(
    object: EnhancedListener | EnhancedAction,
): object is EnhancedAction {
    return 'components' in object;
}

export function isOverviewSwitch(
    object: Switch | EnhancedListener | EnhancedAction,
): object is Switch {
    return 'switch_logic' in object;
}

export function isBool(value: string): boolean {
    return (
        value === 'true' ||
        value === 'false' ||
        value === 'True' ||
        value === 'False'
    );
}

export function debounce<F extends (...args: any[]) => void>(
    func: F,
    waitFor: number,
): (...args: Parameters<F>) => void {
    let timeout: NodeJS.Timeout;

    return function (...args: Parameters<F>) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor);
    };
}
