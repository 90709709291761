import { usePrivy } from '@privy-io/react-auth';
import { Button } from '../components/Core/Button';
import toast from 'react-hot-toast';

const Profile = () => {
    const {
        ready,
        user,
        logout,
        linkEmail,
        unlinkEmail,
        linkWallet,
        unlinkWallet,
        linkGoogle,
        unlinkGoogle,
        linkDiscord,
        unlinkDiscord,
    } = usePrivy();

    if (!ready || !user) {
        return null;
    }

    return (
        <div className="w-full px-6 pt-4">
            <div className="flex flex-col">
                <div className="w-full bg-white rounded-xl px-8 py-4 drop-shadow-sm">
                    <div className="grid grid-cols-[1fr_5fr_2fr] items-center text-xs sm:text-md">
                        <div className="text-lg">User:</div>
                        <div>{user.id}</div>
                        <div className="grid justify-end">
                            <Button
                                size={'md'}
                                variant="outline"
                                onClick={() => logout()}
                            >
                                {'Logout'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4">
                <div className="w-full flex flex-col bg-white rounded-xl px-8 py-4 space-y-4 drop-shadow-sm">
                    <div className="grid grid-cols-[1fr_5fr_2fr] items-center text-xs sm:text-md">
                        <div className="text-lg">Email:</div>
                        <div className={`${!user.email && 'text-orange-400'}`}>
                            {user.email ? user.email.address : 'Not provided'}
                        </div>
                        <div className="grid justify-end">
                            <Button
                                size={'md'}
                                onClick={() => {
                                    user.email
                                        ? unlinkEmail(user.email.address).catch(
                                              (error) => {
                                                  toast.error(error.message);
                                              },
                                          )
                                        : linkEmail();
                                }}
                            >
                                {user.email ? 'Remove Email' : 'Add Email'}
                            </Button>
                        </div>
                    </div>
                    <div className="border-b border-grey-shadow dark:border-grey-night" />
                    <div className="grid grid-cols-[1fr_5fr_2fr] items-center text-xs sm:text-md">
                        <div className="text-lg">Wallet:</div>
                        <div className={`${!user.wallet && 'text-orange-400'}`}>
                            {user.wallet ? user.wallet.address : 'Not provided'}
                        </div>
                        <div className="grid justify-end">
                            <Button
                                size={'md'}
                                onClick={() => {
                                    user.wallet
                                        ? unlinkWallet(
                                              user.wallet.address,
                                          ).catch((error) => {
                                              toast.error(error.message);
                                          })
                                        : linkWallet();
                                }}
                            >
                                {user.wallet ? 'Remove Wallet' : 'Add Wallet'}
                            </Button>
                        </div>
                    </div>
                    <div className="border-b border-grey-shadow dark:border-grey-night" />
                    <div className="grid grid-cols-[1fr_5fr_2fr] items-center text-xs sm:text-md">
                        <div className="text-lg">Google:</div>
                        <div className={`${!user.google && 'text-orange-400'}`}>
                            {user.google ? user.google.email : 'Not provided'}
                        </div>
                        <div className="grid justify-end">
                            <Button
                                size={'md'}
                                onClick={() => {
                                    user.google
                                        ? unlinkGoogle(
                                              user.google.subject,
                                          ).catch((error) => {
                                              toast.error(error.message);
                                          })
                                        : linkGoogle();
                                }}
                            >
                                {user.google ? 'Remove Google' : 'Add Google'}
                            </Button>
                        </div>
                    </div>
                    <div className="border-b border-grey-shadow dark:border-grey-night" />
                    <div className="grid grid-cols-[1fr_5fr_2fr] items-center text-xs sm:text-md">
                        <div className="text-lg">Discord:</div>
                        <div
                            className={`${!user.discord && 'text-orange-400'}`}
                        >
                            {user.discord
                                ? user.discord.username
                                : 'Not provided'}
                        </div>
                        <div className="grid justify-end">
                            <Button
                                size={'md'}
                                onClick={() => {
                                    user.discord
                                        ? unlinkDiscord(
                                              user.discord.subject,
                                          ).catch((error) => {
                                              toast.error(error.message);
                                          })
                                        : linkDiscord();
                                }}
                            >
                                {user.discord
                                    ? 'Remove Discord'
                                    : 'Add Discord'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
