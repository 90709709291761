const PageTitle = ({
    title,
    subtitle,
}: {
    title: string;
    subtitle: string;
}) => {
    return (
        <div className="py-4 pb-5">
            <h1 className="mb-9 text-xl-semibold">
                {title}
                <span className="block text-h5">{subtitle}</span>
            </h1>
        </div>
    );
};

export default PageTitle;
