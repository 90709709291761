import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ArrowRightIcon } from '../../Icons/ArrowRightIcon';
import { snakeToReadable } from '../../../utils/helpers';

export const WorkflowCreationReview = ({
    handleSubmit,
    isModalOpen,
    closeModal,
    workflowName,
    reviewData,
}: {
    handleSubmit: () => void;
    isModalOpen: boolean;
    closeModal: () => void;
    workflowName: string;
    reviewData: { integrationName: string; taskName: string }[];
}) => {
    return (
        <Transition appear show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {`Review ${workflowName} and Submit`}
                                </Dialog.Title>
                                <div className="mt-10">
                                    <div className="flex justify-evenly">
                                        {reviewData.flatMap(
                                            (layer, index: number, array) => {
                                                const appName =
                                                    layer.integrationName;
                                                const logoSrc =
                                                    process.env.PUBLIC_URL +
                                                    `/${appName}.png`;
                                                return [
                                                    <div
                                                        key={index}
                                                        className="flex flex-col p-4 items-center justify-centerrounded-xl"
                                                    >
                                                        <img
                                                            src={logoSrc}
                                                            alt={appName}
                                                            className="w-10 h-10 drop-shadow-sm"
                                                        />
                                                        <div className="flex justify-center pt-4">
                                                            {snakeToReadable(
                                                                layer.integrationName,
                                                            )}
                                                        </div>
                                                        <div className="flex justify-center pt-2 text-sm">
                                                            {snakeToReadable(
                                                                layer.taskName,
                                                            )}
                                                        </div>
                                                    </div>,
                                                    ...(index ===
                                                    array.length - 1
                                                        ? []
                                                        : [
                                                              <div
                                                                  key={`arrow-${index}`}
                                                                  className="flex justify-center items-center"
                                                              >
                                                                  <ArrowRightIcon />
                                                              </div>,
                                                          ]),
                                                ];
                                            },
                                        )}
                                    </div>
                                </div>

                                <div className="mt-10">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={handleSubmit}
                                    >
                                        Reviewed. Submit!
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
