import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { usePrivy } from '@privy-io/react-auth';

import {
    Action,
    CardInput,
    Component,
    Switch,
    Listener,
    Output,
    Task,
    TransitionWorkflowData,
    Inputs,
    InputObjectMap,
    InputObject,
} from '../../utils/types';
import { isBool, isNumber } from '../../utils/helpers';
import { Button } from '../Core/Button';
import { WorkflowCreationReview } from './includes';
import { useFetchIntegrations, useGenerateWorkflowId } from '../../hooks/Data';
import WorkflowCreationCard from './WorkflowCreationCard';
import { useAuth } from '../../context/AuthContext';
import { InputDrawer } from './includes/InputDrawer';
import { Portal } from './includes/Portal';
import { getInputsFromTemplate } from '../../utils/helpers/WorkflowCreationHelper';
import { useWorkflowCount } from '../../context/WorkflowCountContext';

const WorkflowCreation = () => {
    // * Implement state to prevent CSRF and clickjacking

    const location = useLocation();
    const template = location.state?.template;

    // Consume context
    const { getAccessToken } = usePrivy();
    const { setAuthState } = useAuth();
    const { updateWorkflowCount } = useWorkflowCount();

    const { result: generatedWorkflowId, loading: loadingWorkflowId } =
        useGenerateWorkflowId();
    const { data: integrations, loading } = useFetchIntegrations();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [currentCard, setCurrentCard] = useState<CardInput | null>(null);
    const [mandatoryInputs, setMandatoryInputs] = useState<{
        required: Inputs[];
        optional: Inputs[];
    }>({ required: [], optional: [] });

    const [isInputValid, setIsInputValid] = useState<Record<string, boolean[]>>(
        {},
    );

    const [requiredInputObject, setRequiredInputObjects] =
        useState<InputObjectMap>({});

    const [optionalInputObject, setOptionalInputObjects] =
        useState<InputObjectMap>({});

    const [workflowName, setWorkflowName] = useState<string>('');
    useEffect(() => {
        if (!loadingWorkflowId) {
            if (template?.workflowName) {
                setWorkflowName(`${template.workflowName}-modified`);
            } else {
                setWorkflowName(`Workflow-${generatedWorkflowId}`);
            }
        }
    }, [loadingWorkflowId]);
    const [isEditing, setIsEditing] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [cards, setCards] = useState<CardInput[]>([]);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [reviewData, setReviewData] = useState<
        { integrationName: string; taskName: string }[]
    >([]);

    const codeHandled = useRef<boolean>(false);

    const [aiMessage, setAiMessage] = useState<string | null>(null);

    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (template && loading === false) {
            if (template.aiMessage) setAiMessage(template.aiMessage);

            const initialCards: CardInput[] = [];
            let initialRequiredInputObjects: InputObjectMap = {};
            let initialOptionalInputObjects: InputObjectMap = {};

            template.workflowData.forEach(
                (item: TransitionWorkflowData, index: number) => {
                    const cardId = item?.cardId || uuidv4();

                    const card: CardInput = {
                        cardId,
                        layerId: index,
                        integrationName: item.integrationName,
                        taskName: item.taskName,
                        inputs: item.inputs,
                        oneTime: false,
                        taskType:
                            item.taskType === 'combination'
                                ? 'action'
                                : item.taskType === 'listener' ||
                                  item.taskType === 'switch'
                                ? item.taskType
                                : '',
                    };

                    const selectedTask = integrations
                        .find(
                            (integration) =>
                                integration.app === item.integrationName,
                        )
                        ?.tasks.find(
                            (task: Task) => task.name === item.taskName,
                        );

                    const mandatoryRequiredInputs =
                        selectedTask?.inputs.required_inputs;

                    // * Extract required and optional inputs
                    // * The following method assumes that ALL required inputs are set in template because indeces are used for extraction
                    const requiredInputs: InputObject[] = getInputsFromTemplate(
                        item.inputs,
                        selectedTask,
                        initialCards,
                        card,
                        integrations,
                        'required',
                    );

                    const optionalInputs: InputObject[] = getInputsFromTemplate(
                        item.inputs,
                        selectedTask,
                        initialCards,
                        card,
                        integrations,
                        'optional',
                    );

                    initialCards.push(card);
                    initialRequiredInputObjects[cardId] = requiredInputs;
                    initialOptionalInputObjects[cardId] = optionalInputs;

                    if (
                        requiredInputs?.length ===
                            mandatoryRequiredInputs?.length &&
                        !requiredInputs.some((input) => input?.value === '')
                    ) {
                        setIsInputValid((prevState) => ({
                            ...prevState,
                            [cardId]: mandatoryRequiredInputs.map(() => true),
                        }));
                    }
                },
            );
            setRequiredInputObjects(initialRequiredInputObjects);
            setOptionalInputObjects(initialOptionalInputObjects);
            setCards(initialCards);
        } else {
            if (!loading) {
                setCards([
                    {
                        cardId: uuidv4(),
                        layerId: 0,
                        integrationName: '',
                        taskName: '',
                        inputs: [],
                        oneTime: false,
                        taskType: '',
                    },
                ]);
            }
        }
    }, [template, loading]);

    useEffect(() => {
        const codeHandlers: Record<string, (code: string) => void> = {
            GMAIL_CODE: (code) => exchangeCodeForToken(code, 'gmail'),
            DISCORD_CODE: (code) => exchangeCodeForToken(code, 'discord'),
        };

        const handleMessage = (event: any) => {
            if (event.origin === window.location.origin) {
                const handler = codeHandlers[event.data.type];
                if (handler) {
                    const { code } = event.data;
                    if (code && !codeHandled.current) {
                        codeHandled.current = true;
                        handler(code);
                    }
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const exchangeCodeForToken = async (
        code: string,
        service: 'gmail' | 'discord',
    ) => {
        const authToken = await getAccessToken();
        const serviceUrl =
            service === 'gmail'
                ? `${baseURL}/api/gmail/gmail-exchange-code/?code=${code}`
                : `${baseURL}/api/discord/discord-exchange-code/?code=${code}`;

        try {
            const response = await axios.get(serviceUrl, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            setAuthState((prev) => ({
                ...prev,
                [service]: {
                    ...prev[service],
                    hasAccess: response.data.success,
                    isLoading: false,
                },
            }));
        } catch (error) {
            console.error(`Error exchanging code for ${service}`, error);
            setAuthState((prev) => ({
                ...prev,
                [service]: {
                    ...prev[service],
                    hasAccess: false,
                    isLoading: false,
                },
            }));
        } finally {
            codeHandled.current = false;
        }
    };

    function closeModal() {
        setIsModalOpen(false);
    }

    function openModal() {
        setIsModalOpen(true);
    }

    const handleSaveCard = (data: CardInput) => {
        setCards((prevCards) => {
            const existingCardIndex = prevCards.findIndex(
                (card) => card.cardId === data.cardId,
            );

            const newCards =
                existingCardIndex > -1
                    ? prevCards.map((card, index) =>
                          index === existingCardIndex ? data : card,
                      )
                    : [...prevCards, data];

            return newCards;
        });
    };

    const getAvailableOutputs = (currentCardIndex: string): Output[] => {
        const currentCard = cards.find(
            (card) => card.cardId === currentCardIndex,
        );

        if (!currentCard) return [];
        const currentCardLayerId = currentCard.layerId;

        const previousLayerCards = cards.filter(
            (card) => card.layerId < currentCardLayerId,
        );

        const outputs: Output[] = [];

        previousLayerCards.forEach((card) => {
            const integration = integrations.find(
                (int) => int.app === card.integrationName,
            );

            if (!integration) return;

            const task = integration.tasks.find(
                (t: Task) => t.name === card.taskName,
            );

            if (!task || !task.outputs) return;

            task.outputs.forEach((output: string[], index: number) => {
                outputs.push({
                    id: card.cardId + index, // Needed to make ids unique
                    outputElement: output[0],
                    processedOutput: [card.cardId, 'outputs', index],
                    layerId: card.layerId,
                });
            });
        });
        return outputs;
    };

    const handleAddCard = (cardId: string) => {
        const leftCardIndex = cards.findIndex((card) => card.cardId === cardId);

        const newCard = {
            cardId: uuidv4(),
            layerId: leftCardIndex + 1,
            integrationName: '',
            taskName: '',
            inputs: [],
            oneTime: false,
        };

        const updatedCards = [
            ...cards.slice(0, leftCardIndex + 1),
            newCard,
            ...cards.slice(leftCardIndex + 1),
        ];

        for (let i = newCard.layerId + 1; i < updatedCards.length; i++) {
            updatedCards[i].layerId += 1;
        }

        setCards(updatedCards);
    };

    const handleDeleteCard = (cardId: string) => {
        const cardToDelete = cards.find((card) => card.cardId === cardId);
        const updatedCards = cards.filter((card) => card.cardId !== cardId);

        if (!cardToDelete) return;

        for (let i = cardToDelete.layerId; i < updatedCards.length; i++) {
            updatedCards[i].layerId -= 1;
        }

        setCards(updatedCards);
    };

    const handleReview = (cards: CardInput[]) => {
        handleSaveInputDrawer(
            currentCard?.cardId,
            requiredInputObject,
            optionalInputObject,
        );
        openModal();

        const extractedData = cards.map((card) => ({
            integrationName: card.integrationName || '',
            taskName: card.taskName || '',
        }));

        setReviewData(extractedData);
    };

    const handleSubmit = async () => {
        closeModal();

        const workflow_data: (Listener | Action)[] = [];

        for (let i = cards.length - 1; i >= 0; i--) {
            const card = cards[i];
            if (!card.integrationName || !card.taskName || !card.inputs)
                continue;

            const requiredInputsForCard =
                requiredInputObject[card.cardId] || [];
            const optionalInputsForCard =
                optionalInputObject[card.cardId] || [];

            const combinedInputs = [
                ...requiredInputsForCard,
                ...optionalInputsForCard,
            ];

            const transformedInputs = combinedInputs.map((input) => {
                if (input?.reference) {
                    return input.reference;
                } else {
                    return input.value;
                }
            });

            let gates: Omit<Switch, 'type' | 'catch'>[] = [];
            if (card.gateHelper && card.gateHelper.length > 0) {
                const filteredGates = card.gateHelper.filter(
                    (s) => s.selectedIf.processedOutput?.[0] === card.cardId,
                );

                filteredGates.forEach((gate) => {
                    gates.push({
                        io: gate.selectedIf.processedOutput || [],
                        data_type: isNumber(gate.selectedThan)
                            ? 'float'
                            : isBool(gate.selectedThan)
                            ? 'bool'
                            : 'string',
                        switch_logic: {
                            [gate.selectedThan]: [
                                card.layerId + 1,
                                gate.selectedIs,
                            ],
                        },
                    });
                });
            }

            const component: Component = {
                component_id: card.cardId,
                name: card.taskName,
                app: card.integrationName,
                inputs: transformedInputs,
                ...(card.gateHelper && card.gateHelper.length > 0
                    ? { gates }
                    : {}),
            };

            const next = card.layerId < cards.length - 1 ? [i + 1] : [];

            if (card.taskType === 'listener') {
                const listener: Listener = {
                    ...component,
                    type: 'listener',
                    // one_time: card.oneTime,
                    one_time: false,
                    next,
                };
                workflow_data.unshift(listener);
            } else if (card.taskType === 'action') {
                const action: Action = {
                    type: 'combination',
                    components: [component],
                    logic: null,
                    next,
                };
                workflow_data.unshift(action);
            } else {
                console.error(
                    'The tasks ',
                    card.taskName,
                    ' does not have a type!',
                );
            }
        }
        const workflowSubmission = {
            workflow_name:
                workflowName === ''
                    ? `Workflow-${generatedWorkflowId}`
                    : workflowName,

            start_layers: [0],
            workflow_data,
        };

        const authToken = await getAccessToken();

        try {
            await axios.post(
                `${baseURL}/app/workflow-creation/`,
                {
                    workflow: workflowSubmission,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            );
            toast.success(<> Submit Successful! 🎉 </>, { duration: 8000 });
            setCards([
                {
                    cardId: Date.now().toString(),
                    layerId: 0,
                    integrationName: '',
                    taskName: '',
                    inputs: [],
                    oneTime: false,
                    taskType: '',
                },
            ]);
            updateWorkflowCount((prevCount) => prevCount + 1);
        } catch (error: any) {
            toast.error(<>Error: {error?.message}</>);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^[a-zA-Z0-9- ]*$/.test(value)) {
            setWorkflowName(value);
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const handleBlur = () => {
        if (workflowName === '') {
            setWorkflowName(`Workflow-${generatedWorkflowId}`);
        }
        setIsEditing(false);
        setIsValid(true);
    };

    function openDrawer(
        card: CardInput,
        mandatoryInputs: { required: Inputs[]; optional: Inputs[] },
    ) {
        if (isDrawerOpen) {
            handleSaveInputDrawer(
                currentCard?.cardId,
                requiredInputObject,
                optionalInputObject,
            );
            setCurrentCard(card);
            setMandatoryInputs(mandatoryInputs);
        } else {
            setCurrentCard(card);
            setMandatoryInputs(mandatoryInputs);
            setIsDrawerOpen(true);
        }
    }

    function closeDrawer() {
        setIsDrawerOpen(false);
    }

    function handleSaveInputDrawer(
        cardId: string | undefined,
        reqInpObj: InputObjectMap,
        optInpObj: InputObjectMap,
    ) {
        if (!cardId) return;
        setCards((prevCards) => {
            const existingCardIndex = prevCards.findIndex(
                (card) => card.cardId === cardId,
            );

            const cardToChange = prevCards.find(
                (card) => card.cardId === cardId,
            );

            if (!cardToChange) return [...prevCards];

            const nonEmptyRequiredInputs =
                reqInpObj[cardId]?.filter((input) => input?.value !== '') || [];

            const nonEmptyOptionalInputs =
                optInpObj[cardId]?.filter((input) => input?.value !== '') || [];

            const newCards =
                existingCardIndex > -1
                    ? prevCards.map((card, index) =>
                          index === existingCardIndex
                              ? {
                                    ...card,
                                    inputs: [
                                        ...nonEmptyRequiredInputs,
                                        ...nonEmptyOptionalInputs,
                                    ],
                                }
                              : card,
                      )
                    : [
                          ...prevCards,
                          {
                              ...cardToChange,
                              inputs: [
                                  ...nonEmptyRequiredInputs,
                                  ...nonEmptyOptionalInputs,
                              ],
                          },
                      ];

            return newCards;
        });
    }

    return (
        <>
            <div className="w-full px-6 pt-4">
                <div className="flex flex-col">
                    <div className="w-full flex-col bg-white rounded-xl px-8 py-4 drop-shadow-sm z-40">
                        <div className="w-full flex items-center justify-between">
                            <div>
                                <span className="text-md">Creating:</span>
                                {isEditing ? (
                                    <input
                                        aria-label="Workflow Name Input Field"
                                        autoFocus
                                        onBlur={handleBlur}
                                        className={`text-md bg-transparent border-none outline-none mx-2 border-b ${
                                            !isValid && 'text-red-500'
                                        }`}
                                        placeholder={'Insert Workflow Name'}
                                        value={workflowName}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    <span
                                        className="text-md mx-2 cursor-text rounded-lg hover:text-grey-elephant/50"
                                        onClick={() => setIsEditing(true)}
                                    >
                                        {workflowName}
                                    </span>
                                )}
                                {!isValid && isEditing && (
                                    <span className="flex text-sm text-red-500 mt-1">
                                        Only letters, numbers, - and ' ' are
                                        allowed.
                                    </span>
                                )}
                            </div>
                            <div className="flex">
                                <Button
                                    isDisabled={
                                        !cards.every(
                                            (card) =>
                                                isInputValid[card.cardId] &&
                                                isInputValid[card.cardId].every(
                                                    (value) => value === true,
                                                ),
                                        )
                                    }
                                    size="md"
                                    variant="filled"
                                    onClick={() => handleReview(cards)}
                                >
                                    {cards.every(
                                        (card) =>
                                            isInputValid[card.cardId] &&
                                            isInputValid[card.cardId].every(
                                                (value) => value === true,
                                            ),
                                    )
                                        ? 'Review to Submit'
                                        : 'Set up cards'}
                                </Button>
                            </div>
                        </div>
                        {aiMessage && (
                            <div>
                                <span className="text-md italic">
                                    {aiMessage}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-col space-y-12 overflow-x-auto whitespace-nowrap pt-4 pb-8">
                    <div className="flex">
                        {!loading &&
                            cards.map((card, index) => (
                                <WorkflowCreationCard
                                    key={card.cardId}
                                    integrationData={integrations}
                                    cardData={card}
                                    onAdd={handleAddCard}
                                    onSave={handleSaveCard}
                                    onDelete={handleDeleteCard}
                                    first={index === 0 ? true : false}
                                    hasSecond={cards.length > 1 ? true : false}
                                    last={index === cards.length - 1}
                                    onOpenDrawer={openDrawer}
                                    isInputValid={
                                        isInputValid[card.cardId] || []
                                    }
                                    setIsInputValid={setIsInputValid}
                                    setRequiredInputObjects={
                                        setRequiredInputObjects
                                    }
                                    setOptionalInputObjects={
                                        setOptionalInputObjects
                                    }
                                    onCloseDrawer={closeDrawer}
                                />
                            ))}
                        <Portal>
                            <InputDrawer
                                open={isDrawerOpen}
                                onClose={closeDrawer}
                                currentCard={
                                    currentCard || { cardId: '', layerId: -1 }
                                }
                                mandatoryInputs={mandatoryInputs}
                                onSaveInput={handleSaveInputDrawer}
                                requiredInputObject={requiredInputObject}
                                setRequiredInputObjects={
                                    setRequiredInputObjects
                                }
                                optionalInputObject={optionalInputObject}
                                setOptionalInputObjects={
                                    setOptionalInputObjects
                                }
                                generateOutputs={getAvailableOutputs}
                                isInputValid={isInputValid}
                                setIsInputValid={setIsInputValid}
                            />
                        </Portal>
                    </div>
                </div>
            </div>
            <WorkflowCreationReview
                handleSubmit={handleSubmit}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                workflowName={workflowName}
                reviewData={reviewData!}
            />
        </>
    );
};

export default WorkflowCreation;
