import { isNumber } from '../helpers';
import { CardInput, InputObject, Output, Task } from '../types';

export function getInputsFromTemplate(
    inputs: (string | (string | number)[])[] | undefined,
    selectedTask: Task,
    initialCards: CardInput[],
    currentCard: CardInput,
    data: any[],
    service: 'required' | 'optional',
): InputObject[] {
    const getAvailableOutputsFromTemplate = (): Output[] => {
        if (!currentCard) return [];
        const currentCardLayerId = currentCard.layerId;

        const previousLayerCards = initialCards.filter(
            (card) => card.layerId < currentCardLayerId,
        );

        const outputs: Output[] = [];

        previousLayerCards.forEach((card) => {
            const integration = data.find(
                (int) => int.app === card.integrationName,
            );

            if (!integration) return;

            const task = integration.tasks.find(
                (t: Task) => t.name === card.taskName,
            );

            if (!task || !task.outputs) return;

            task.outputs.forEach((output: string[], index: number) => {
                outputs.push({
                    id: card.cardId + index,
                    outputElement: output[0],
                    processedOutput: [card.cardId, 'outputs', index],
                    layerId: card.layerId,
                });
            });
        });
        return outputs;
    };

    const processInput = (
        input: string | (string | number)[],
        index: number,
    ): InputObject => {
        if (Array.isArray(input) && input.length === 3) {
            const thisOutput = getAvailableOutputsFromTemplate().find(
                (output) => {
                    if (!isNumber(input?.[0])) {
                        return (
                            output.processedOutput?.[0] === input?.[0] &&
                            output.processedOutput?.[2] === input?.[2]
                        );
                    } else {
                        return (
                            output.layerId === input?.[0] &&
                            output.processedOutput?.[2] === input?.[2]
                        );
                    }
                },
            );
            return {
                value: thisOutput?.outputElement || '',
                reference: thisOutput?.processedOutput,
            };
        } else {
            const requiredInputsOfTask =
                selectedTask.inputs.required_inputs[index];

            const containsValidOption =
                requiredInputsOfTask?.options &&
                requiredInputsOfTask.options.some((item) => item === input);

            if (containsValidOption && !Array.isArray(input))
                return { value: input, options: requiredInputsOfTask.options };

            if (requiredInputsOfTask?.options && !Array.isArray(input))
                return {
                    value: '',
                    placeholder: requiredInputsOfTask.description,
                    options: requiredInputsOfTask.options,
                };

            return { value: typeof input === 'string' ? input : '' };
        }
    };

    const requiredInputsLength = selectedTask?.inputs.required_inputs.length;
    const processedInputs =
        inputs?.map((input, index) => processInput(input, index)) || [];
    const filteredInputs = processedInputs.filter((_, index) =>
        service === 'required'
            ? index < requiredInputsLength
            : index >= requiredInputsLength,
    );
    // TODO Can be simplified because we are not using filteredInputs for the optional inputs case

    // Ensure that length mandatory required_inputs match the created inputs array for required
    if (service === 'required') {
        while (filteredInputs.length < requiredInputsLength) {
            filteredInputs.push({
                value: '',
                placeholder:
                    selectedTask?.inputs.required_inputs[filteredInputs.length]
                        ?.description || 'Placeholder for required input',
            });
        }
    }

    return filteredInputs;
}
