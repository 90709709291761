import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';
import axios from 'axios';

import {
    Action,
    Listener,
    Switch,
    Template,
    TransitionTemplate,
    TransitionWorkflowData,
} from '../../utils/types';
import { isAction, isGate } from '../../utils/helpers';

type UseWorkflowLibrary = {
    templates: Template[];
    selectedCategory: string;
    setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
    uniqueCategories: string[];
    handleTemplateClick: (workflow: Template) => void;
};

export function useWorkflowLibrary(): UseWorkflowLibrary {
    const { getAccessToken } = usePrivy();
    const navigate = useNavigate();

    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const [templates, setTemplates] = useState<Template[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('All');

    useEffect(() => {
        const fetchData = async () => {
            const authToken = await getAccessToken();
            try {
                const response = await axios.get(
                    `${baseURL}/app/template-workflows/`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    },
                );
                setTemplates(response.data.all_templates);
            } catch (error) {
                console.error('Error fetching integrations:', error);
            }

            // const test = await axios.post(
            //     `${baseURL}/app/workflow-chatbot/`,
            //     { query: 'transfer funds then send an email' },
            //     {
            //         headers: {
            //             Authorization: `Bearer ${authToken}`,
            //         },
            //     },
            // );

            // console.log('test');
            // console.log(test);
        };

        fetchData();
    }, []);

    const allCategories = templates.flatMap((template) => {
        return template.workflow_data.flatMap((item) => {
            if (isGate(item)) return [];
            if ('category' in item) {
                return item.category;
            } else {
                return item.components?.map((component) => component.category);
            }
        });
    });

    const uniqueCategories = Array.from(new Set(allCategories));

    function handleTemplateClick(workflow: Template) {
        const templateWorkflowData: TransitionWorkflowData[] =
            workflow?.workflow_data?.map((data: Listener | Action | Switch) => {
                if (isGate(data))
                    return {
                        integrationName: '',
                        taskName: '',
                        taskType: '',
                        inputs: [],
                    };
                return isAction(data)
                    ? {
                          integrationName: data.components?.[0].app,
                          taskName: data.components?.[0].name,
                          taskType: data.type,
                          inputs: data.components?.[0]?.inputs,
                      }
                    : {
                          integrationName: data.app,
                          taskName: data.name,
                          taskType: data.type,
                          inputs: data.inputs,
                      };
            });
        const template: TransitionTemplate = {
            workflowData: templateWorkflowData,
        };
        navigate('/workflow-creation', { state: { template } });
    }

    return {
        templates,
        selectedCategory,
        setSelectedCategory,
        uniqueCategories,
        handleTemplateClick,
    };
}
