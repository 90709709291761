import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { PrivyProvider, usePrivy } from '@privy-io/react-auth';
import WorkflowCreation from './components/WorkflowCreation';

import Layout from './components/Core/Layout';
import WorkflowOverview from './pages/WorkflowOverview';
import WorkflowLibrary from './pages/WorkflowLibrary';
import Dashboard from './components/Dashboard';
import IntegrationLibrary from './pages/IntegrationLibrary';
import toast from 'react-hot-toast';
import Auth from './pages/Auth';
import Profile from './pages/Profile';
import OAuthRedirect from './pages/OAuthRedirect';
import { AuthProvider } from './context/AuthContext';
import { WorkflowCountProvider } from './context/WorkflowCountContext';

const AppRoutes = () => {
    const { authenticated, ready } = usePrivy();

    const renderRoute = (Component: React.ComponentType) => {
        if (!ready) return null;
        if (!authenticated) return <Navigate to="/auth" replace />;
        return (
            <Layout>
                <Component />
            </Layout>
        );
    };

    return (
        <Routes>
            <Route path="/auth" element={!ready ? null : <Auth />} />
            <Route path="/dashboard" element={renderRoute(Dashboard)} />
            <Route
                path="/workflow-creation"
                element={renderRoute(WorkflowCreation)}
            />
            <Route
                path="/workflow-overview"
                element={renderRoute(WorkflowOverview)}
            />
            <Route path="/templates" element={renderRoute(WorkflowLibrary)} />
            <Route
                path="/integrations"
                element={renderRoute(IntegrationLibrary)}
            />
            <Route path="/profile" element={renderRoute(Profile)} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
            <Route
                path="/discord-redirect"
                element={<OAuthRedirect api={'DISCORD'} />}
            />
            <Route
                path="/oauth/return/GMailAPI"
                element={<OAuthRedirect api={'GMAIL'} />}
            />
        </Routes>
    );
};

const App = () => {
    return (
        <BrowserRouter>
            <PrivyProvider
                appId={process.env.REACT_APP_PRIVY_APP_ID || ''}
                onSuccess={() => toast.success('Successfully logged in')}
                config={{
                    loginMethods: [
                        'wallet',
                        'google',
                        'apple',
                        'discord',
                        'twitter',
                        'github',
                        'email',
                    ],
                    embeddedWallets: {
                        createOnLogin: 'all-users',
                        requireUserPasswordOnCreate: false,
                    },
                    appearance: {
                        theme: '#2f373d',
                        accentColor: '#ffffff',
                        logo: 'https://www.ax.al/_next/image?url=%2Faxal_text.png&w=256&q=100',

                        showWalletLoginFirst: true,
                    },
                }}
            >
                <AuthProvider>
                    <WorkflowCountProvider>
                        <AppRoutes />
                    </WorkflowCountProvider>
                </AuthProvider>
            </PrivyProvider>
        </BrowserRouter>
    );
};

export default App;
