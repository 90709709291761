import { useEffect } from 'react';

type OAuthRedirectProps = {
    api: string;
};

const OAuthRedirect: React.FC<OAuthRedirectProps> = ({ api }) => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            window.opener.postMessage(
                { type: `${api}_CODE`, code },
                window.location.origin,
            );
        }
        window.close();
    }, []);

    return (
        <div className="flex justify-center items-center">Redirecting...</div>
    );
};

export default OAuthRedirect;
