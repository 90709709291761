import { useEffect } from 'react';
import { Button } from '../components/Core/Button';
import { usePrivy } from '@privy-io/react-auth';
import { useNavigate } from 'react-router-dom';
import { LogoExpSidebar } from '../components/Icons/LogoExpSidebar';

const Auth = () => {
    const { authenticated, login } = usePrivy();
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated) {
            navigate('/dashboard');
        }
    }, [authenticated]);

    return (
        <>
            <div className="min-h-screen flex">
                <div className="flex-1 flex flex-col items-center justify-center bg-blue-100 p-20">
                    <LogoExpSidebar className="w-[16rem] sm:w-[24rem] mb-16" />
                    <div className="flex gap-4">
                        <Button
                            className="w-36 sm:w-64 bg-blue-400 text-white p-2 rounded-lg border-none hover:bg-blue-700"
                            onClick={() => {
                                window.open(
                                    'https://docs.google.com/forms/d/e/1FAIpQLSeELvgu323xyMUwtXjhlF4GUvxIZ1kjB7NGVHmRpuLI0unYPA/viewform?usp=sf_link',
                                    '_blank',
                                    'noopener,noreferrer',
                                );
                            }}
                        >
                            Sign Up
                        </Button>
                        <Button
                            className="w-36 sm:w-64 bg-blue-300 text-white p-2 rounded-lg border-none hover:bg-blue-400"
                            onClick={login}
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Auth;
