import {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';

import {
    CardInput,
    InputObject,
    InputObjectMap,
    Inputs,
    Output,
    PrevOutputMap,
    SelectedInputOptionMap,
} from '../../../utils/types';
import { debounce } from '../../../utils/helpers';
import CustomListBox from '../../Core/CustomListbox';
import { Button } from '../../Core/Button';
import { ComboboxOption } from '../../Core/Combobox';
import { Input } from '../../Core/Input';

import { Option } from '../../../utils/types';

const DRAWER_MARGIN = '8px';
const DRAWER_OFFSET = '10px';
const DRAWER_WIDTH = '420px';
const DRAWER_WIDTH_XL = '490px';

const CloseDrawer = ({ onClick }: { onClick: () => void }) => {
    return (
        <div
            className={`relative group cursor-pointer h-full rounded-xl transition-all`}
            style={{
                padding: `24px calc(18px + ${DRAWER_OFFSET}) 24px 14px`,
                transition: `300ms ease background-color, 300ms ease margin`,
            }}
            onClick={onClick}
        >
            <div className="absolute inset-0 rounded-l-xl group-hover:opacity-50 group-hover:bg-gray-200 transition" />
            <ChevronDoubleRightIcon className="w-6 h-6 transition group-hover:translate-x-1" />
        </div>
    );
};

export const AccountDrawerWrapper = ({
    open,
    children,
}: {
    open: boolean;
    children: ReactNode;
}) => {
    const marginRight = open ? '0' : `-${DRAWER_WIDTH}`;
    const marginRightXL = open ? '0' : `-${DRAWER_WIDTH_XL}`;

    return (
        <div
            className="rounded-lg text-base overflow-y-scroll scrollbar-hide bg-white border border-gray-300 shadow-lg transition-all duration-300"
            style={{
                marginRight:
                    window.innerWidth >= 1440 ? marginRightXL : marginRight,
                width:
                    window.innerWidth >= 1440 ? DRAWER_WIDTH_XL : DRAWER_WIDTH,
                height: '100%',
                backgroundColor: 'white',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                zIndex: 'auto',
                position: 'relative',
                top: 'auto',
                transition: `top 300ms, 300ms`,
            }}
        >
            {children}
        </div>
    );
};

const Container = ({ children }: { children: ReactNode }) => {
    return (
        <div
            className="flex flex-row overflow-hidden fixed right-0 top-0 z-30 pt-44"
            style={{
                height: `calc(100% - 2 * ${DRAWER_MARGIN})`,
                right: `calc(${DRAWER_MARGIN} + 16px)`,
            }}
        >
            {children}
        </div>
    );
};

interface InputDrawerProps {
    open: boolean;
    onClose: () => void;
    currentCard: CardInput;
    mandatoryInputs: { required: Inputs[]; optional: Inputs[] };
    onSaveInput: (
        cardId: string,
        reqInpObj: InputObjectMap,
        optInpObj: InputObjectMap,
    ) => void;
    requiredInputObject: InputObjectMap;
    setRequiredInputObjects: React.Dispatch<
        React.SetStateAction<InputObjectMap>
    >;
    optionalInputObject: InputObjectMap;
    setOptionalInputObjects: React.Dispatch<
        React.SetStateAction<InputObjectMap>
    >;
    generateOutputs: (cardIndex: string) => Output[];
    isInputValid: Record<string, boolean[]>;
    setIsInputValid: React.Dispatch<
        React.SetStateAction<Record<string, boolean[]>>
    >;
}

export const InputDrawer: React.FC<InputDrawerProps> = ({
    open: isInputDrawerOpen,
    onClose,
    currentCard,
    mandatoryInputs,
    onSaveInput,
    requiredInputObject,
    setRequiredInputObjects,
    optionalInputObject,
    setOptionalInputObjects,
    generateOutputs,
    isInputValid,
    setIsInputValid,
}) => {
    const [selectedPrevOutputs, setSelectedPrevOutputs] =
        useState<PrevOutputMap>({});

    const [selectedInputOption, setSelectedInputOption] =
        useState<SelectedInputOptionMap>({});

    const [availableOutputs, setAvailableOutputs] = useState<Output[]>([]);

    const scrollRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!isInputDrawerOpen) {
            scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [isInputDrawerOpen]);

    useEffect(() => {
        if (
            !optionalInputObject[currentCard.cardId] ||
            optionalInputObject[currentCard.cardId]?.length === 0
        ) {
            setOptionalInputObjects((prev) => {
                return {
                    ...prev,
                    [currentCard.cardId]: mandatoryInputs.optional.map(
                        (input) => {
                            return {
                                value: '',
                                placeholder: input.description,
                            };
                        },
                    ),
                };
            });
            setSelectedPrevOutputs((prevState) => {
                return {
                    ...prevState,
                    [currentCard.cardId]: { required: [], optional: [] },
                };
            });
        }
        if (
            requiredInputObject[currentCard.cardId]?.some(
                (item) => item.options,
            )
        ) {
            const newSelectedOptions: Option[] = [
                ...(selectedInputOption[currentCard.cardId] ||
                    Array.from({
                        length: requiredInputObject[currentCard.cardId].length,
                    }).map(() => ({ id: '', label: '' }))),
            ];
            requiredInputObject[currentCard.cardId]?.forEach((item, index) => {
                if (item?.options) {
                    newSelectedOptions[index].label = item.value;
                }
            });
            setSelectedInputOption((prevState) => ({
                ...prevState,
                [currentCard.cardId]: newSelectedOptions,
            }));
        }
    }, [mandatoryInputs.required, mandatoryInputs.optional]);

    useMemo(() => {
        const generatedOutputs = generateOutputs(currentCard.cardId);
        setAvailableOutputs(generatedOutputs);
    }, [generateOutputs, currentCard.cardId]);

    const prepareOutputOptions = useMemo((): ComboboxOption[] => {
        return availableOutputs.flatMap((output, index) => {
            return [
                {
                    id: output.id,
                    name: output.outputElement,
                    displayValue: output.outputElement,
                },
            ];
        });
    }, [availableOutputs]);

    const validateReqInputChange = useCallback(
        (index: number, value: string) => {
            const isValid = value !== '';
            setIsInputValid((prevState) => {
                const newValidity = [
                    ...(prevState[currentCard.cardId] ||
                        mandatoryInputs.required.map(() => false)),
                ];
                newValidity[index] = isValid;
                return {
                    ...prevState,
                    [currentCard.cardId]: newValidity,
                };
            });
        },
        [currentCard.cardId],
    );

    const debouncedValidateInput = useCallback(
        debounce(validateReqInputChange, 200),
        [validateReqInputChange],
    );

    function handleRequiredInputChange(value: string, index: number) {
        const currentCardId = currentCard.cardId;
        const oldInputs = requiredInputObject[currentCardId] || [];

        const newInputs = [...oldInputs];
        newInputs[index] = {
            value,
        };

        // When prevOutput was selected but input field changes
        // then change selectedPrevOutputs back to 'Prev Output'
        // TODO: Optimize so that this only runs once on input field change
        if (selectedPrevOutputs[currentCard.cardId]?.required[index]) {
            const oldSelectedPrevOutput = [
                ...(selectedPrevOutputs[currentCard.cardId]?.required || []),
            ];
            oldSelectedPrevOutput[index] = {
                ...(oldSelectedPrevOutput[index] || ''),
                label: 'Prev Output',
            };
            // setSelectedPrevOutputs((prev) => {
            //     return { ...prev, required: oldSelectedPrevOutput };
            // });
            setSelectedPrevOutputs((prevState) => {
                return {
                    ...prevState,
                    [currentCard.cardId]: {
                        ...(prevState[currentCard.cardId] || []),
                        required: oldSelectedPrevOutput,
                    },
                };
            });
        }

        setRequiredInputObjects((prev) => ({
            ...prev,
            [currentCardId]: newInputs,
        }));

        debouncedValidateInput(index, value);
    }

    function handleOptionalInputChange(value: string, index: number) {
        const currentCardId = currentCard.cardId;
        const oldInputs = optionalInputObject[currentCardId] || [];

        const newInputs = [...oldInputs];
        newInputs[index] = {
            value,
        };

        // When prevOutput was selected but input field changes
        // then change selectedPrevOutputs back to 'Prev Output'
        // TODO: Optimize so that this only runs once on input field change
        if (selectedPrevOutputs[currentCard.cardId]?.optional[index]) {
            const oldSelectedPrevOutput = [
                ...(selectedPrevOutputs[currentCard.cardId]?.optional || []),
            ];
            oldSelectedPrevOutput[index] = {
                ...(oldSelectedPrevOutput[index] || ''),
                label: 'Prev Output',
            };
            setSelectedPrevOutputs((prevState) => {
                return {
                    ...prevState,
                    [currentCard.cardId]: {
                        ...(prevState[currentCard.cardId] || []),
                        optional: oldSelectedPrevOutput,
                    },
                };
            });
        }

        setOptionalInputObjects((prev) => ({
            ...prev,
            [currentCardId]: newInputs,
        }));
    }

    function handleCloseInputDrawer() {
        const newValidity = mandatoryInputs.required.map((_, index) => {
            const correspondingInput = requiredInputObject[
                currentCard.cardId
            ]?.[index]
                ? requiredInputObject[currentCard.cardId]?.[index]
                : false;
            return correspondingInput && correspondingInput.value !== '';
        });
        setIsInputValid((prevState) => {
            return { ...prevState, [currentCard.cardId]: newValidity };
        });

        onSaveInput(
            currentCard.cardId,
            requiredInputObject,
            optionalInputObject,
        );

        onClose();
    }

    function handlePrevOutputSelection(
        selectedOutput: Option,
        inputIndex: number,
        service: string,
    ) {
        if (!selectedOutput) return;
        const newSelectedPrevOutput =
            service === 'required'
                ? [...(selectedPrevOutputs[currentCard.cardId]?.required || [])]
                : [
                      ...(selectedPrevOutputs[currentCard.cardId]?.optional ||
                          []),
                  ];

        newSelectedPrevOutput[inputIndex] = {
            id: selectedOutput.id,
            label: selectedOutput.label,
        };

        const newInputs =
            service === 'required'
                ? [...(requiredInputObject[currentCard.cardId] || [])]
                : [...(optionalInputObject[currentCard.cardId] || [])];

        const outputReference = generateOutputs(currentCard.cardId)?.find(
            (output) => output.id === selectedOutput.id,
        )?.processedOutput;

        newInputs[inputIndex] = {
            value: selectedOutput.label,
            reference: outputReference,
        };

        setSelectedPrevOutputs((prevState) => {
            return {
                ...prevState,
                [currentCard.cardId]: {
                    ...(prevState[currentCard.cardId] || {
                        required: [],
                        optional: [],
                    }),
                    [service]: newSelectedPrevOutput,
                },
            };
        });

        if (service === 'required') {
            setRequiredInputObjects((prevState) => ({
                ...prevState,
                [currentCard.cardId]: newInputs,
            }));
            debouncedValidateInput(inputIndex, selectedOutput.label);
        } else {
            setOptionalInputObjects((prevState) => ({
                ...prevState,
                [currentCard.cardId]: newInputs,
            }));
        }
    }

    function handleInputOptionsSelection(
        selectedOption: Option,
        inputIndex: number,
    ) {
        if (!selectedOption) return;

        const oldInputs = requiredInputObject[currentCard.cardId] || [];
        const newInputs = [...oldInputs];
        newInputs[inputIndex] = {
            ...newInputs[inputIndex],
            value: selectedOption.label,
        };
        setRequiredInputObjects((prev) => ({
            ...prev,
            [currentCard.cardId]: newInputs,
        }));

        // const newSelectedOptions = [...selectedInputOption];
        const newSelectedOptions = [
            ...(selectedInputOption[currentCard.cardId] || []),
        ];
        newSelectedOptions[inputIndex] = {
            id: selectedOption.id,
            label: selectedOption.label,
        };
        // setSelectedInputOption(newSelectedOptions);
        setSelectedInputOption((prevState) => ({
            ...prevState,
            [currentCard.cardId]: newSelectedOptions,
        }));

        debouncedValidateInput(inputIndex, selectedOption.label);
    }

    function renderRequiredInputs(input: InputObject, index: number) {
        return (
            <div key={index}>
                {input.options ? (
                    <CustomListBox
                        selected={{
                            id:
                                selectedInputOption[currentCard.cardId]?.[index]
                                    ?.id || '',
                            label:
                                (selectedInputOption[currentCard.cardId] !==
                                    null &&
                                    selectedInputOption[currentCard.cardId]?.[
                                        index
                                    ]?.label) ||
                                input?.placeholder ||
                                'Select Option',
                        }}
                        setSelected={(value) =>
                            handleInputOptionsSelection(value, index)
                        }
                        options={input.options.map((option, index) => {
                            return {
                                id: currentCard.cardId + index,
                                label: option,
                            };
                        })}
                    />
                ) : (
                    <div className="flex gap-2">
                        <Input
                            aria-label="Required Input Text Input Field"
                            className={`${
                                generateOutputs(currentCard.cardId).length > 0
                                    ? 'w-3/5'
                                    : 'w-full'
                            } border ${
                                !isInputValid[currentCard.cardId]?.[index] &&
                                isInputValid[currentCard.cardId]?.length > 0
                                    ? 'bg-red-50 border-red-300'
                                    : 'bg-grey-light border-grey-shadow '
                            } text-grey-night placeholder-grey-night dark:border-grey-night dark:bg-grey-dark dark:text-grey-metal dark:placeholder-grey-metal`}
                            type="text"
                            value={
                                requiredInputObject[currentCard.cardId]?.[index]
                                    ?.value || ''
                            }
                            placeholder={input?.placeholder || ''}
                            onChange={(e) =>
                                handleRequiredInputChange(e, index)
                            }
                        />
                        {generateOutputs(currentCard.cardId).length > 0 && (
                            <CustomListBox
                                selected={{
                                    id:
                                        selectedPrevOutputs[currentCard.cardId]
                                            ?.required[index]?.id || '',
                                    label:
                                        (requiredInputObject[
                                            currentCard.cardId
                                        ]?.[index] &&
                                            selectedPrevOutputs[
                                                currentCard.cardId
                                            ]?.required[index]?.label) ||
                                        'Prev Output',
                                }}
                                setSelected={(value) =>
                                    handlePrevOutputSelection(
                                        value,
                                        index,
                                        'required',
                                    )
                                }
                                options={prepareOutputOptions.map((option) => {
                                    return {
                                        id: option.id,
                                        label: option.name,
                                    };
                                })}
                                className="w-2/5 shrink-0"
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }

    function handleAddOptionalInput(value?: string) {
        if (optionalInputObject[currentCard.cardId]?.length === 0) return;
        setOptionalInputObjects((prev) => ({
            ...prev,
            [currentCard.cardId]: [
                ...prev[currentCard.cardId],
                { value: value || '' },
            ],
        }));
    }

    function handleDeleteOptionalInput(index: number) {
        if (index >= optionalInputObject[currentCard.cardId]?.length) return;
        let tempArray = [...(optionalInputObject[currentCard.cardId] || [])];
        tempArray.splice(index, 1);
        setOptionalInputObjects({ [currentCard.cardId]: tempArray });
    }

    function renderOptionalInputs(input: InputObject, index: number) {
        return (
            <div key={index} className="flex gap-2 w-full">
                <div
                    className={`${
                        generateOutputs(currentCard.cardId).length > 0
                            ? 'w-3/5'
                            : 'w-full'
                    } `}
                >
                    <Input
                        aria-label="Optional Input Text Input Field"
                        className={`border border-grey-shadow bg-grey-light text-grey-night placeholder-grey-night dark:border-grey-night dark:bg-grey-dark dark:text-grey-metal dark:placeholder-grey-metal`}
                        type="text"
                        value={
                            optionalInputObject[currentCard.cardId]?.[index]
                                ?.value || ''
                        }
                        placeholder={input?.placeholder || ''}
                        onChange={(e) => handleOptionalInputChange(e, index)}
                    />
                </div>
                {generateOutputs(currentCard.cardId).length > 0 && (
                    <div className="w-2/5 shrink-0">
                        <div className="flex w-full">
                            <CustomListBox
                                selected={{
                                    id:
                                        selectedPrevOutputs[currentCard.cardId]
                                            ?.optional[index]?.id || '',
                                    label:
                                        (optionalInputObject[
                                            currentCard.cardId
                                        ]?.[index] &&
                                            selectedPrevOutputs[
                                                currentCard.cardId
                                            ]?.optional[index]?.label) ||
                                        'Prev Output',
                                }}
                                setSelected={(value) =>
                                    handlePrevOutputSelection(
                                        value,
                                        index,
                                        'optional',
                                    )
                                }
                                options={prepareOutputOptions.map((option) => {
                                    return {
                                        id: option.id,
                                        label: option.name,
                                    };
                                })}
                                className="w-4/5 grow"
                            />

                            {(index !== 0 ||
                                optionalInputObject[currentCard.cardId]
                                    ?.length > 1) && (
                                <div className="flex w-1/5 pl-2">
                                    <Button
                                        size="sm"
                                        variant="filled"
                                        className="items-center px-3 !bg-primary-low border-none drop-shadow-md"
                                        onClick={() =>
                                            handleDeleteOptionalInput(index)
                                        }
                                    >
                                        X
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {(index !== 0 ||
                    optionalInputObject[currentCard.cardId]?.length > 1) &&
                    generateOutputs(currentCard.cardId).length === 0 && (
                        <div className="flex">
                            <Button
                                size="sm"
                                variant="filled"
                                className="items-center px-3 !bg-primary-low border-none drop-shadow-md"
                                onClick={() => handleDeleteOptionalInput(index)}
                            >
                                X
                            </Button>
                        </div>
                    )}
            </div>
        );
    }

    return (
        <Container>
            {isInputDrawerOpen && (
                <CloseDrawer onClick={handleCloseInputDrawer} />
            )}
            {
                <AccountDrawerWrapper open={isInputDrawerOpen}>
                    <div className="py-6">
                        <div className="px-4 sm:px-6">
                            {/** InputDrawer title */}
                            <span className="text-lg text-gray-800">
                                Select inputs
                            </span>
                        </div>
                        {/** Divider */}
                        <div className="mt-6 border-b border-grey-shadow dark:border-grey-night" />
                        {/** Required input section */}
                        <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-8">
                            {/* {mandatoryInputs.required?.length > 0 && ( */}
                            {requiredInputObject[currentCard.cardId]?.length >
                                0 && (
                                <div>
                                    <p className="pb-2 text-sm">
                                        Required inputs:
                                    </p>
                                    <div className="flex flex-col w-full gap-2">
                                        {/* {mandatoryInputs.required?.map( */}
                                        {requiredInputObject[
                                            currentCard.cardId
                                        ]?.map((input, index) => {
                                            return renderRequiredInputs(
                                                input,
                                                index,
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                            {/** Optional input section */}
                            {/* {mandatoryInputs.optional?.length > 0 && ( */}
                            {optionalInputObject[currentCard.cardId]?.length >
                                0 && (
                                <div>
                                    <p className="pb-2 text-sm">
                                        Optional inputs:
                                    </p>
                                    <div className="flex flex-col w-full gap-2">
                                        {optionalInputObject[
                                            currentCard.cardId
                                        ]?.map((input, index) => {
                                            return renderOptionalInputs(
                                                input,
                                                index,
                                            );
                                        })}
                                    </div>
                                    <div className="pt-2">
                                        <div
                                            className="flex items-center justify-center group cursor-pointer"
                                            onClick={() =>
                                                handleAddOptionalInput()
                                            }
                                        >
                                            <div className="flex-1 border-t-2 border-gray-400 group-hover:border-gray-500"></div>
                                            <div className="mx-3 bg-gray-400 rounded-full w-5 h-5 flex items-center justify-center group-hover:bg-gray-500">
                                                <span className="text-white font-bold text-xs">
                                                    +
                                                </span>
                                            </div>
                                            <div className="flex-1 border-t-2 border-gray-400 group-hover:border-gray-500"></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </AccountDrawerWrapper>
            }
        </Container>
    );
};
