import * as React from 'react';

export const LogoRetrSidebar = React.forwardRef<
    SVGSVGElement,
    React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
    <svg
        viewBox="0 0 1000 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(0.523946 0.851751 -0.8548 0.518957 132.523 167.666)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(0.523953 -0.851747 0.854797 0.518963 263.007 334.333)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <rect
            x="235.046"
            y="325.073"
            width="27.9609"
            height="9.25924"
            fill="#0560FD"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(-0.523946 -0.851751 0.8548 -0.518957 365.532 167.666)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(-0.523953 0.851747 -0.854797 -0.518963 235.047 1)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <rect
            x="263.009"
            y="10.2578"
            width="27.9609"
            height="9.25924"
            transform="rotate(-180 263.009 10.2578)"
            fill="#0560FD"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(0.523946 0.851751 -0.8548 0.518957 29.9998 334.333)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(0.523953 -0.851747 0.854797 0.518963 160.484 500.999)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <rect
            x="132.523"
            y="491.74"
            width="27.9609"
            height="9.25924"
            fill="#0560FD"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(-0.523946 -0.851751 0.8548 -0.518957 263.009 334.333)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(-0.523953 0.851747 -0.854797 -0.518963 132.525 167.666)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <rect
            x="160.486"
            y="176.926"
            width="27.9609"
            height="9.25924"
            transform="rotate(-180 160.486 176.926)"
            fill="#0560FD"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(0.523946 0.851751 -0.8548 0.518957 235.046 334.332)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(0.523953 -0.851747 0.854797 0.518963 365.53 500.999)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <rect
            x="337.569"
            y="491.74"
            width="27.9609"
            height="9.25924"
            fill="#0560FD"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(-0.523946 -0.851751 0.8548 -0.518957 468.055 334.332)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <line
            y1="-12.5"
            x2="195.675"
            y2="-12.5"
            transform="matrix(-0.523953 0.851747 -0.854797 -0.518963 337.571 167.666)"
            stroke="#0560FD"
            strokeWidth="25"
        />
        <rect
            x="365.532"
            y="176.925"
            width="27.9609"
            height="9.25924"
            transform="rotate(-180 365.532 176.925)"
            fill="#0560FD"
        />
    </svg>
));

LogoRetrSidebar.displayName = 'LogoRetrSidebar';
