import { createBreakpoint } from 'react-use';

export const useBreakpoint = createBreakpoint({
    xs: 320, // Extra small devices (portrait phones)
    sm: 480, // Small devices (smaller phones)
    md: 768, // Medium devices (tablets)
    lg: 1024, // Large devices (small laptops/desktops)
    xl: 1280, // Extra large devices (large laptops/desktops)
    xxl: 1440, // Bigger desktops
    xxxl: 1920, // Ultra large screens (4K, ultra-wides)
});
